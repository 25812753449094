// Types
import type { I_apiGetWidget_Data } from "@/hooks/requests/apiGetWidget/apiGetWidget.types";

/** Переводы для виджета */
const TRANSLATIONS: I_apiGetWidget_Data["translations"] = {
    // |---------------------------|
    // | Общие элементы управления |
    // |---------------------------|

    // Кнопка "Назад"
    tiw__back: {
        key: "tiw__back",
        ru: "Назад",
        en: "Back",
        kz: "Кері",
    },

    // Кнопка "Продолжить"
    tiw__continue: {
        key: "tiw__continue",
        ru: "Продолжить",
        en: "Continue",
        kz: "Жалғастыру",
    },

    // Кнопка из меню "Начать заново"
    tiw__restart: {
        key: "tiw__restart",
        ru: "Начать заново",
        en: "Restart",
        kz: "Қайтадан бастау",
    },

    // Кнопка из меню "Отменить"
    tiw__cancel: {
        key: "tiw__cancel",
        ru: "Отменить",
        en: "Cancel",
        kz: "Бас тарту",
    },

    // Кнопка "Свернуть" (свернуть виджет)
    tiw__rollDown: {
        key: "tiw__rollDown",
        ru: "Свернуть",
        en: "Roll",
        kz: "Жасыру",
    },

    // Кнопка "Скрыть" (скрыть инфо "Как найти IMEI")
    tiw__hide: {
        key: "tiw__hide",
        ru: "Скрыть",
        en: "Hide",
        kz: "Жасыру",
    },

    // Кнопка "Закрыть"
    tiw__close: {
        key: "tiw__close",
        ru: "Закрыть",
        en: "Close",
        kz: "Жабу",
    },

    // Placeholder of input
    tiw__notRequired: {
        key: "tiw__notRequired",
        ru: "Не обязательно",
        en: "Not required",
        kz: "Міндетті емес",
    },

    // crunch
    tiw__crunch__needHelp: {
        key: "tiw__crunch__needHelp",
        ru: "Нужна помощь? ",
        en: "Do you need help? ",
        kz: "Көмек керек пе? ",
    },
    tiw__crunch__contactUs: {
        key: "tiw__crunch__contactUs",
        ru: "Свяжитесь <span style='user-select:none;color:initial;cursor:initial;'>c нами</span>",
        en: "Contact <span style='user-select:none;color:initial;cursor:initial;'>us</span>",
        kz: "<span style='user-select:none;color:initial;cursor:initial;'>Бізге</span> хабарласыңыз",
    },

    // |-------------------|
    // | Валидация (общее) |
    // |-------------------|

    tiw__validation__success: {
        key: "tiw__validation__success",
        ru: "Успешно",
        en: "Successfully",
        kz: "Сәтті",
    },
    tiw__validation__undefined: {
        key: "tiw__validation__undefined",
        ru: "Для указанного поля валидация не настроена",
        en: "Validation is not configured for the specified field",
        kz: "Көрсетілген өріс үшін валидация орнатылмаған",
    },

    // |-----------------|
    // | Валидация полей |
    // |-----------------|

    // Поле "File"
    tiw__validation__file_choosePhoto: {
        key: "tiw__validation__file_choosePhoto",
        ru: "Выберите фото",
        en: "Select a photo",
        kz: "Фотосуретті таңдаңыз",
    },
    tiw__validation__file_maxSize: {
        key: "tiw__validation__file_maxSize",
        ru: "Превышен максимально допустимый (10 мб) размер файла. Выберите другое фото",
        en: "The maximum allowed file size (10 MB) has been exceeded. Choose another photo",
        kz: "Рұқсат етілген ең үлкен (10 мб) файл өлшемінен асып кетті. Басқа фотосуретті таңдаңыз",
    },
    tiw__validation__file_wrongExt: {
        key: "tiw__validation__file_wrongExt",
        ru: 'Расширение загружаемого Вами файла не поддерживается для загрузки. Допустимы файлы с расширением ".jpg, .jpeg, .bmp, .png или .heic"',
        en: 'The file extension you are downloading is not supported for download. Files with the extension " are allowed.jpg, .jpeg, .bmp, .png or .heic"',
        kz: 'Сіз жүктеген файл кеңейтіміне жүктеу үшін қолдау көрсетілмейді. Кеңейтімі бар файлдар жарамды ".jpg, .jpeg, .bmp, .png немесе .heic"',
    },

    // Поле "ФИО"
    tiw__validation__fio_required: {
        key: "tiw__validation__fio_required",
        ru: "Поле ФИО обязательно к заполнению",
        en: "The full name field must be filled in",
        kz: "Аты жөні өрісі міндетті түрде толтырылады",
    },
    tiw__validation__fio_range: {
        key: "tiw__validation__fio_range",
        ru: "Поле ФИО должно иметь от 10 до 50 символов",
        en: "The full name field must have from 10 to 50 characters",
        kz: "Аты жөні өрісінде 10 дан 50 ге дейін таңба болуы керек",
    },
    tiw__validation__fio_symbols: {
        key: "tiw__validation__fio_symbols",
        ru: 'Поле ФИО содержит недопустимые символы пунктуации кроме "-" или повторяющийся 2 раза под ряд символ пробела',
        en: 'The FULL NAME field contains invalid punctuation characters other than "-" or a space character repeated 2 times in a row',
        kz: 'Аты-жөні өрісінде "-" немесе қатарынан 2 рет қайталанатын бос орыннан басқа рұқсат етілмеген тыныс белгілері бар',
    },

    // Поле "IMEI"
    tiw__validation__imei_required: {
        key: "tiw__validation__imei_required",
        ru: "Поле IMEI обязательно к заполнению",
        en: "The IMEI field must be filled in",
        kz: "IMEI өрісі міндетті түрде толтырылады",
    },
    tiw__validation__imei_range: {
        key: "tiw__validation__imei_range",
        ru: "Поле IMEI должно состоять только из цифр и не менее 15 символов",
        en: "The IMEI field must consist of only numbers and at least 15 characters",
        kz: "IMEI өрісі тек сандардан және кем дегенде 15 таңбадан тұруы керек",
    },

    // Поле "ИНН"
    tiw__validation__inn_required: {
        key: "tiw__validation__inn_required",
        ru: "Поле ИНН обязательно к заполнению",
        en: "The INN field must be filled in",
        kz: "СТН өрісі міндетті түрде толтырылады",
    },
    tiw__validation__inn_range: {
        key: "tiw__validation__inn_range",
        ru: "Поле ИНН должно состоять только из цифр и в диапазоне 10-12 символов",
        en: "The INN field should consist only of numbers and in the range of 10-12 characters",
        kz: "СТН өрісі тек сандардан және 10-12 таңбадан тұруы керек",
    },

    // Поле "email"
    tiw__validation__email_required: {
        key: "tiw__validation__email_required",
        ru: "Поле Email обязательно к заполнению",
        en: "The Email field must be filled in",
        kz: "Email өрісі міндетті түрде толтырылады",
    },
    tiw__validation__email_incorrect: {
        key: "tiw__validation__email_incorrect",
        ru: "Введите корректный Email",
        en: "Enter the correct Email address",
        kz: "Дұрыс электрондық поштаны енгізіңіз",
    },

    // Поле "Телефон"
    tiw__validation__phone_required: {
        key: "tiw__validation__phone_required",
        ru: "Поле Телефон обязательно к заполнению",
        en: "The Phone field must be filled in",
        kz: "Телефон өрісі міндетті түрде толтырылады",
    },
    tiw__validation__phone_range: {
        key: "tiw__validation__phone_range",
        ru: "Поле Телефон должно быть в диапазоне 14-18 символов",
        en: "The Phone field should be in the range of 14-18 characters",
        kz: "Телефон өрісі 14-18 таңбадан тұруы керек",
    },

    // |---------------------------------|
    // | Описание грейдов (HandedDevice) |
    // |---------------------------------|

    tiw__grades__Q_stateInfo: {
        key: "tiw__grades__Q_stateInfo",
        ru: "Идеальное",
        en: "Perfect",
        kz: "Mінсіз",
    },
    tiw__grades__Q_description: {
        key: "tiw__grades__Q_description",
        ru: "Устройство в идеальном состоянии",
        en: "The device is in perfect condition",
        kz: "Құрылғы мінсіз күйде",
    },
    tiw__grades__A_stateInfo: {
        key: "tiw__grades__A_stateInfo",
        ru: "Отличное",
        en: "Excellent",
        kz: "Өте жақсы",
    },
    tiw__grades__A_description: {
        key: "tiw__grades__A_description",
        ru: "Устройство в отличном состоянии",
        en: "The device is in excellent condition",
        kz: "Құрылғы өте жақсы күйде",
    },
    tiw__grades__B_stateInfo: {
        key: "tiw__grades__B_stateInfo",
        ru: "Хорошее",
        en: "Good",
        kz: "Жақсы",
    },
    tiw__grades__B_description: {
        key: "tiw__grades__B_description",
        ru: "Устройство в хорошем состоянии",
        en: "The device is in good condition",
        kz: "Құрылғы жақсы күйде",
    },
    tiw__grades__C_stateInfo: {
        key: "tiw__grades__C_stateInfo",
        ru: "Со следами эксплуатации",
        en: "With traces of exploitation",
        kz: "Пайдалану іздері бар",
    },
    tiw__grades__C_description: {
        key: "tiw__grades__C_description",
        ru: "Устройство со следами эксплуатации",
        en: "A device with traces of operation",
        kz: "Пайдалану іздері бар құрылғы",
    },
    tiw__grades__D_stateInfo: {
        key: "tiw__grades__D_stateInfo",
        ru: "Со значительными следами эксплуатации",
        en: "With significant traces of exploitation",
        kz: "Пайдаланудың елеусіз іздері бар",
    },
    tiw__grades__D_description: {
        key: "tiw__grades__D_description",
        ru: "Устройство со значительными следами эксплуатации",
        en: "A device with significant traces of operation",
        kz: "Елеулісіз пайдалану іздері бар құрылғы",
    },
    tiw__grades__E_stateInfo: {
        key: "tiw__grades__E_stateInfo",
        ru: "Критическое",
        en: "Critical",
        kz: "Сыни",
    },
    tiw__grades__E_description: {
        key: "tiw__grades__E_description",
        ru: "Устройство с критическими дефектами",
        en: "A device with critical defects",
        kz: "Сыни ақаулары бар құрылғы",
    },

    // |------------|
    // | Компоненты |
    // |------------|

    // Компонент "SalesDevice"
    tiw__vselect__notFound: {
        key: "tiw__vselect__notFound",
        ru: "Извините. Совпадений не найдено.",
        en: "Sorry. No matches were found.",
        kz: "Кешіріңіз. Сәйкестік табылған жоқ.",
    },

    // Компонент "SalesDevice"
    tiw__salesDevice__title: {
        key: "tiw__salesDevice__title",
        ru: "Покупаемое устройство",
        en: "The device to be sale",
        kz: "Сатып алынатын құрылғы",
    },

    // Компонент "HandedDevice"
    tiw__handedDevice__title: {
        key: "tiw__handedDevice__title",
        ru: "Сдаваемое устройство",
        en: "The device to be handed over",
        kz: "Өткізілетін құрылғы",
    },
    tiw__handedDevice__opportunity: {
        key: "tiw__handedDevice__opportunity",
        ru: "Вы можете загрузить дополнительные фото согласно инструкции, в этом случае стоимость выкупа может быть увеличена",
        en: "You can upload additional photos according to the instructions, in which case the purchase price may be increased",
        kz: "Нұсқаулыққа сәйкес қосымша фотосуреттерді жүктеуге болады, бұл жағдайда сатып алу құнын артуы мүмкін",
    },
    tiw__handedDevice__unknownGrade: {
        key: "tiw__handedDevice__unknownGrade",
        ru: "Состояние устройства не определено",
        en: "The device status is not defined",
        kz: "Құрылғының күйі анықталмады",
    },
    tiw__handedDevice__deviceTerminated: {
        key: "tiw__handedDevice__deviceTerminated",
        ru: "К сожалению, данное устройство в таком состоянии выкупу не подлежит",
        en: "Unfortunately, this device is not redeemable in this condition",
        kz: "Өкінішке орай, бұл құрылғы мұндай жағдайда сатып алуға жатпайды",
    },

    // Компонент "CalculatedDevice"
    tiw__calculatedDevice__title_pre: {
        key: "tiw__calculatedDevice__title_pre",
        ru: "Предварительная цена",
        en: "Preliminary price",
        kz: "Алдын ала бағасы",
    },
    tiw__calculatedDevice__title_fin: {
        key: "tiw__calculatedDevice__title_fin",
        ru: "Финальная цена",
        en: "Final price",
        kz: "Соңғы баға",
    },
    tiw__calculatedDevice__price_list_costNewDevice: {
        key: "tiw__calculatedDevice__price_list_costNewDevice",
        ru: "Цена нового устройства",
        en: "The price of a new device",
        kz: "Жаңа құрылғының бағасы",
    },
    tiw__calculatedDevice__price_list_tradeInSale: {
        key: "tiw__calculatedDevice__price_list_tradeInSale",
        ru: "Скидка в Trade-In",
        en: "Trade-In sale",
        kz: "Trade-in-гі жеңілдік",
    },
    tiw__calculatedDevice__price_list_tradeInSale_from: {
        key: "tiw__calculatedDevice__price_list_tradeInSale_from",
        ru: "от [[PRICE]] [[CURRENCY]]",
        en: "from [[PRICE]] [[CURRENCY]]",
        kz: "[[PRICE]] [[CURRENCY]] бастап",
    },
    tiw__calculatedDevice__price_list_tradeInSale_until: {
        key: "tiw__calculatedDevice__price_list_tradeInSale_until",
        ru: "до [[PRICE]] [[CURRENCY]]",
        en: "until [[PRICE]] [[CURRENCY]]",
        kz: "[[PRICE]] [[CURRENCY]] дейін",
    },
    tiw__calculatedDevice__price_list_additionalSale: {
        key: "tiw__calculatedDevice__price_list_additionalSale",
        ru: "Экстра-бонус за покупку в Trade-in",
        en: "Extra bonus for buying in Trade-in",
        kz: "Trade-in-де сатып алу үшін экстра-бонус",
    },
    tiw__calculatedDevice__price_list_additionalSale_valid: {
        key: "tiw__calculatedDevice__price_list_additionalSale_valid",
        ru: "Действует с [[START]] до [[END]]",
        en: "Valid from [[START]] to [[END]]",
        kz: "[[START]] бастап [[END]] дейін жарамды",
    },
    // tiw__calculatedDevice__price_list_startAt: {
    //     key: "tiw__calculatedDevice__price_list_startAt",
    //     ru: "Действует с",
    //     en: "Valid from",
    //     kz: "Бірге әрекет етеді",
    // },
    // tiw__calculatedDevice__price_list_from: {
    //     key: "tiw__calculatedDevice__price_list_from",
    //     ru: "от",
    //     en: "from",
    //     kz: "бастап",
    // },
    // tiw__calculatedDevice__price_list_until: {
    //     key: "tiw__calculatedDevice__price_list_until",
    //     ru: "до",
    //     en: "until",
    //     kz: "дейін",
    // },
    tiw__calculatedDevice__price_list_tradeInInfo: {
        key: "tiw__calculatedDevice__price_list_tradeInInfo",
        ru: "Эта сумма определена на основании ваших ответов и является предварительной. Вы узнаете точный размер скидки, как только устройство проверит наш эксперт. В 86% случаев финальная оценка совпадает с предварительной.",
        en: "This amount is determined based on your answers and is preliminary. You will find out the exact discount amount as soon as our expert checks the device. In 86% of cases, the final assessment coincides with the preliminary one.",
        kz: "ұл сома сіздің жауаптарыңыз негізінде анықталады және алдын ала баға болып табылады. Жеңілдіктің нақты мөлшерін біздің сарапшы текесергеннен кейін білетін боласыз. 86% жағдайда соңғы бағалау алдын ала бағалаумен сәйкес келеді.",
    },

    // Компонент "loadPanel"
    tiw__loadPanel__downloadPhoto: {
        key: "tiw__loadPanel__downloadPhoto",
        ru: "Загрузить фото",
        en: "Upload a photo",
        kz: "Фотоны жүктеу",
    },
    tiw__loadPanel__downloadPhoto_err: {
        key: "tiw__loadPanel__downloadPhoto_err",
        ru: "Загрузите фото",
        en: "Upload a photo",
        kz: "Фотосуретті жүктеңіз",
    },
    tiw__loadPanel__downloadPhoto_ambiguous: {
        key: "tiw__loadPanel__downloadPhoto_ambiguous",
        ru: "Чтобы уточнить грейд сделайте дополнительные фото",
        en: "To clarify the grade, take additional photos",
        kz: "Грейдті нақтылау үшін қосымша фотосуреттер түсіріңіз",
    },
    tiw__loadPanel__downloadPhoto_badQuality: {
        key: "tiw__loadPanel__downloadPhoto_badQuality",
        ru: "Фото плохого качества",
        en: "Photos of poor quality",
        kz: "Сапасыз фотосурет",
    },
    tiw__loadPanel__downloadPhoto_irrelevant: {
        key: "tiw__loadPanel__downloadPhoto_irrelevant",
        ru: "Фото не соответствует требованию",
        en: "Фото талапқа сәйкес келмейді",
        kz: "Сапасыз фотосурет",
    },

    // Компонент "QR Panel"
    tiw__qrPanel__title: {
        key: "tiw__qrPanel__title",
        ru: "QR для загрузки с телефона",
        en: "QR code to download from your phone",
        kz: "Телефоннан жүктеуге арналған QR",
    },
    tiw__qrPanel__info: {
        key: "tiw__qrPanel__info",
        // ru: "Для этого отсканируйте QR-код со своего телефона. Когда фотографии будут загружены с телефона, Вы сможете продолжить оформление заявки.",
        ru: "Если у вас нет фото сдаваемого устройства, отсканируйте QR-код камерой телефона и загрузите их для продолжения заявки.",
        en: "To do this, scan the QR code from your phone. After The photos will be uploaded from your phone, you can continue registration of the application.",
        kz: "Ол үшін телефоныңыздан  QR кодты сканерлеңіз. Фотосуреттер телефоннан жүктелген кезде, Сіз өтінішті рәсімдеуді жалғастыра аласыз.",
    },

    // |--------|
    // | Экраны |
    // |--------|

    // Экран "buyDevice"
    tiw__buyDevice__title: {
        key: "tiw__buyDevice__title",
        ru: "Какое устройство вы хотите купить?",
        en: "Which device do you want to buy?",
        kz: "Сіз қандай құрылғыны сатып алғыңыз келеді?",
    },
    tiw__buyDevice__subtitle: {
        key: "tiw__buyDevice__subtitle",
        ru: "Выберите желаемую модель",
        en: "Select the desired model",
        kz: "Қажетті модельді таңдаңыз",
    },
    tiw__buyDevice__brand: {
        key: "tiw__buyDevice__brand",
        ru: "Бренд",
        en: "Brand",
        kz: "Бренд",
    },
    tiw__buyDevice__category: {
        key: "tiw__buyDevice__category",
        ru: "Категория",
        en: "Category",
        kz: "Санат",
    },
    tiw__buyDevice__model: {
        key: "tiw__buyDevice__model",
        ru: "Модель",
        en: "Model",
        kz: "Үлгі",
    },

    // Экран "chooseDevice"
    tiw__chooseDevice__title: {
        key: "tiw__chooseDevice__title",
        ru: "Какое устройство вы хотите сдать?",
        en: "Which device do you want to donate?",
        kz: "Қандай құрылғыны өткізгіңіз келеді?",
    },
    tiw__chooseDevice__subtitle: {
        key: "tiw__chooseDevice__subtitle",
        ru: "Укажите модель, чтобы узнать размер скидки",
        en: "Specify the model to find out the discount amount",
        kz: "Жеңілдік мөлшерін білу үшін моделін көрсетіңіз",
    },
    tiw__chooseDevice__brand: {
        key: "tiw__chooseDevice__brand",
        ru: "Бренд",
        en: "Brand",
        kz: "Бренд",
    },
    tiw__chooseDevice__category: {
        key: "tiw__chooseDevice__category",
        ru: "Категория",
        en: "Category",
        kz: "Санат",
    },
    tiw__chooseDevice__model: {
        key: "tiw__chooseDevice__model",
        ru: "Модель",
        en: "Model",
        kz: "Үлгі",
    },
    tiw__chooseDevice__other: {
        key: "tiw__chooseDevice__other",
        ru: "Память / Модель / Год",
        en: "Memory / Model / Year",
        kz: "Жад / Өлшемі / Жылы",
    },
    tiw__chooseDevice__color: {
        key: "tiw__chooseDevice__color",
        ru: "Цвет",
        en: "Color",
        kz: "Түсі",
    },
    tiw__chooseDevice__terms: {
        key: "tiw__chooseDevice__terms",
        ru: 'Больше информации вы найдете в <a class="tiw__link tiw__link_theme-light" target="blank" href="https://breezy.band/kz.pdf">Условиях использования Breezy Trade-In</a>.',
        en: 'You will find more information in the <a class="tiw__link tiw__link_theme-light" target="blank" href="https://breezy.band/kz.pdf">Breezy Trade-In Terms of Uses </a>.',
        kz: '<a class="tiw__link tiw__link_theme-light" target="blank" href="https://breezy.band/kz.pdf">Қосымша ақпаратты Breezy Trade-In</a> пайдалану шарттарынан таба аласыз.',
    },

    // Экран "howYouRateDevice"
    // tiw__howYouRateDevice__title: {
    //     key: "tiw__howYouRateDevice__title",
    //     ru: "В каком состоянии ваше устройство?",
    //     en: "What is the condition of your device?",
    //     kz: "Сіздің құрылғыңыз қандай күйде?",
    // },
    // tiw__howYouRateDevice__title_pre: {
    //     key: "tiw__howYouRateDevice__title_pre",
    //     ru: "Предварительная цена",
    //     en: "Preliminary price",
    //     kz: "Алдын ала баға",
    // },
    // tiw__howYouRateDevice__subtitle: {
    //     key: "tiw__howYouRateDevice__subtitle",
    //     ru: "Выберите вариант, который точнее всего описывает состояние сдаваемого устройства.",
    //     en: "Select the option that best describes the condition of the device being delivered.",
    //     kz: "Берілген құрылғының күйін дәл сипаттайтын опцияны таңдаңыз.",
    // },
    tiw__howYouRateDevice__howGetSale: {
        key: "tiw__howYouRateDevice__howGetSale",
        ru: "Как получить скидку",
        en: "How to get a discount",
        kz: "Жеңілдікті қалай алуға болады",
    },
    tiw__howYouRateDevice__howGetSale_info: {
        key: "tiw__howYouRateDevice__howGetSale_info",
        ru: "Вы сможете воспользоваться скидкой, как только подтвердите оценку устройства. Для этого нужно сфотографировать сдаваемый гаджет. Это займет до 5 минут.",
        en: "You will be able to take advantage of the discount as soon as you confirm the evaluation of the device. To do this, you need to take a picture of the gadget being handed over. It will take up to 5 minutes.",
        kz: "Сіз құрылғының бағасын растағаннан кейін жеңілдікті пайдалана аласыз. Мұны істеу үшін өткізілетін гаджетті суретке түсіруіңіз керек. Бұл 5 минут уақытты алады.",
    },
    tiw__howYouRateDevice__howGetSale_info_goShop: {
        key: "tiw__howYouRateDevice__howGetSale_info_goShop",
        ru: "Чтобы воспользоваться скидкой и сдать свое устройство в Trade-In, необходимо прийти в магазин. Устройство будет оценено на месте, после чего вам предоставят скидку на новую покупку.",
        en: "To take advantage of the discount and hand over your device to the Trade-In, you need to come to the store. The device will be evaluated on the spot, after which you will be given a discount on a new purchase.",
        kz: "Жеңілдікті пайдалану және құрылғыны Trade-In-ге өткізу үшін дүкенге келу керек. Құрылғы сол жерде бағаланады, содан кейін сізге жаңа сатып алуға жеңілдік беріледі.",
    },

    // Экран "enterImei"
    tiw__enterImei__title: {
        key: "tiw__enterImei__title",
        ru: "Введите IMEI сдаваемого устройства",
        en: "Enter the IMEI of the device you are handing over",
        kz: "Өткізілетін құрылғының IMEI енгізіңіз",
    },
    tiw__enterImei__imei: {
        key: "tiw__enterImei__imei",
        ru: "IMEI",
        en: "IMEI",
        kz: "IMEI",
    },
    tiw__enterImei__imei_notAccepted: {
        key: "tiw__enterImei__imei_notAccepted",
        ru: "К сожалению данное устройство не принимается",
        en: "Unfortunately, this device is not accepted",
        kz: "Бұл құрылғыны қабылдауға жатпайды",
    },
    tiw__enterImei__imei_uncorrect: {
        key: "tiw__enterImei__imei_uncorrect",
        ru: "Проверьте правильность ввода IMEI",
        en: "Check that the IMEI is entered correctly",
        kz: "IMEI енгізуінің дұрыстығын тексеріңіз",
    },
    tiw__enterImei__imei_reEvaluate: {
        key: "tiw__enterImei__imei_reEvaluate",
        ru: "Пожалуйста, перепройдите оценку, выбрав правильную модель",
        en: "Please re-evaluate by choosing the correct model",
        kz: "Дұрыс үлгіні таңдау арқылы бағалауды қайталаңыз",
    },
    tiw__enterImei__yourModel: {
        key: "tiw__enterImei__yourModel",
        ru: "Модель вашего устройства",
        en: "The model of your device",
        kz: "Құрылғының моделі",
    },
    tiw__enterImei__howFindImei: {
        key: "tiw__enterImei__howFindImei",
        ru: "Как найти IMEI?",
        en: "How do I find the IMEI?",
        kz: "IMEI қалай табуға болады?",
    },
    tiw__enterImei__howFindImei_step1: {
        key: "tiw__enterImei__howFindImei_step1",
        ru: "Наберите <strong>*#06#</strong> чтобы найти IMEI",
        en: "Dial <strong>*#06#</strong> to find the IMEI",
        kz: "IMEI табу үшін <strong>*#06#</strong> теріңіз",
    },
    tiw__enterImei__howFindImei_step2: {
        key: "tiw__enterImei__howFindImei_step2",
        ru: "Или найдите IMEI в настройках устройства",
        en: "Or find the IMEI in the device settings",
        kz: "Немесе құрылғы баптауларынан IMEI табыңыз",
    },

    // Экран "downloadPhotos"
    tiw__downloadPhotos__title: {
        key: "tiw__downloadPhotos__title",
        ru: "Загрузите фото сдаваемого устройства",
        en: "Upload a photo of the rented device",
        kz: "Өткізілетін құрылғының фотосуретін жүктеңіз",
    },
    tiw__downloadPhotos__qrInfo: {
        key: "tiw__downloadPhotos__qrInfo",
        ru: "Используйте фотографии в формате <strong>JPG, BMP, HEIC</strong> или <strong>PNG</strong> с разрешением <strong>72 PPI</strong>. Для быстрой загрузки размер файла не должен превышать <strong>10 МБ</strong>",
        en: "Use photos in the format of <strong>JPG, BMP, PNG</strong> or <strong>HEIC</strong> with a resolution of <strong>72 PPI</strong>. For fast uploading, the file size should not exceed <strong>10 MB</strong>",
        kz: "<strong>72 PPI</strong> ажыратымдылығы бар <strong>JPG, BMP, HEIC</strong> немесе <strong>PNG</strong> фотосуреттерін пайдаланыңыз. Жылдам жүктеу үшін файл өлшемі <strong>10 МБ</strong> тан аспауы керек.",
    },

    // Экран "operatorExecuting"
    tiw__operatorExecuting__title_loading: {
        key: "tiw__operatorExecuting__title_loading",
        ru: "Подготовка к обработке",
        en: "Preparation for processing",
        kz: "Өңдеуге дайындық",
    },
    tiw__operatorExecuting__message_loading: {
        key: "tiw__operatorExecuting__message_loading",
        ru: "Фотографии загружены, скоро начнётся оценка",
        en: "The photos have been uploaded, the evaluation will begin soon",
        kz: "Фотосуреттер жүктелді, енді бағалау басталады",
    },
    tiw__operatorExecuting__title_processing: {
        key: "tiw__operatorExecuting__title_processing",
        ru: "Идет изучение фотографий",
        en: "Photos are being studied",
        kz: "Фотосуреттерді зерттеу жүріп жатыр",
    },
    tiw__operatorExecuting__message_processing: {
        key: "tiw__operatorExecuting__message_processing",
        ru: "Оценка в процессе, пожалуйста, подождите 2-3 минуты",
        en: "Evaluation is in progress, please wait 2-3 minutes",
        kz: "Бағалау жүріп жатыр, 2-3 минут күтіңіз",
    },
    tiw__operatorExecuting__title_done: {
        key: "tiw__operatorExecuting__title_done",
        ru: "Все фото обработаны",
        en: "All photos have been processed",
        kz: "Барлық фотосуреттер өңделді",
    },
    tiw__operatorExecuting__message_done: {
        key: "tiw__operatorExecuting__message_done",
        ru: "Все фотографии были оценены, результат появится в течение 30 секунд",
        en: "All photos have been evaluated, the result will appear within 30 seconds",
        kz: "Барлық фотосуреттер өңделеді, нәтиже 30 секунд ішінде пайда болады",
    },
    tiw__operatorExecuting__sorryText: {
        key: "tiw__operatorExecuting__sorryText",
        ru: "К сожалению, оценка устройства потребовала больше времени, чем ожидалось. Приносим извинения за возникшие неудобства. Пожалуйста, не закрывайте окно и подождите ещё немного - результат вот-вот появится на экране",
        en: "Unfortunately, the evaluation of the device took longer than expected. We apologize for any inconvenience. Please do not close the window and wait a little longer - the result is about to appear on the screen",
        kz: "Өкінішке орай, құрылғыны бағалау күткеннен көп уақытты алды. Туындаған қолайсыздықтар үшін кешірім сұраймыз. Терезені жаппаңыз және тағы біраз күтіңіз - нәтиже экранда пайда болады",
    },

    // Экран "evaluationResult"
    tiw__evaluationResult__title: {
        key: "tiw__evaluationResult__title",
        ru: "Результат оценки",
        en: "Evaluation result",
        kz: "Бағалау нәтижесі",
    },
    tiw__evaluationResult__downloadMore: {
        key: "tiw__evaluationResult__downloadMore",
        ru: "Дозагрузить фото",
        en: "Upload more photos",
        kz: "Фотоларды қосымша жүктеу",
    },

    // Экран "successfulEvaluation"
    tiw__successfulEvaluation__title: {
        key: "tiw__successfulEvaluation__title",
        ru: "Время узнать цену!",
        en: "It's time to find out the price!",
        kz: "Бағаны білуге уақыт келді!",
    },
    tiw__successfulEvaluation__subtitle: {
        key: "tiw__successfulEvaluation__subtitle",
        ru: "Теперь вы точно знаете, сколько сэкономите в Trade-In. Осталось подписать SMS-договор",
        en: "Now you know exactly how much you will save in Trade-In. It remains to sign an SMS agreement",
        kz: "Енді сіз Trade-In-де қанша үнемдейтіндігіңізді білесіз. SMS-шартқа қол қою қалды",
    },
    tiw__successfulEvaluation__imei_title: {
        key: "tiw__successfulEvaluation__imei",
        ru: "IMEI вашего устройства",
        en: "IMEI of your device",
        kz: "Құрылғының IMEI",
    },
    tiw__successfulEvaluation__imei: {
        key: "tiw__successfulEvaluation__imei",
        ru: "IMEI",
        en: "IMEI",
        kz: "IMEI",
    },
    tiw__successfulEvaluation__fio_title: {
        key: "tiw__successfulEvaluation__fio_title",
        ru: "Данные для заключения договора",
        en: "Data for the conclusion of the contract",
        kz: "Шарт жасауға арналған деректер",
    },
    tiw__successfulEvaluation__fio: {
        key: "tiw__successfulEvaluation__fio",
        ru: "ФИО",
        en: "Full name",
        kz: "ТАӘ",
    },
    tiw__successfulEvaluation__inn: {
        key: "tiw__successfulEvaluation__inn",
        ru: "Идентификационный номер (ИНН)",
        en: "Identification number (INN)",
        kz: "Сәйкестендіру нөмірі (ЖСН) ",
    },
    tiw__successfulEvaluation__email: {
        key: "tiw__successfulEvaluation__email",
        ru: "Email",
        en: "Email",
        kz: "Email",
    },
    tiw__successfulEvaluation__phone: {
        key: "tiw__successfulEvaluation__phone",
        ru: "Телефон",
        en: "Phone",
        kz: "Телефон",
    },
    tiw__successfulEvaluation__checkbox_personal: {
        key: "tiw__successfulEvaluation__checkbox_personal",
        ru: 'Я соглашаюсь на обработку моих <a class="tiw__link tiw__link_theme-light" target="blank" href="https://breezy.band/kz.pdf#personal-data"> персональных данных </a> и <a class="tiw__link tiw__link_theme-light" target="blank" href="https://breezy.band/kz.pdf"> условия Trade-In </a>',
        en: 'I agree to the processing of my <a class="tiw__link tiw__link_theme-light" target="blank" href="https://breezy .band/kz.pdf#personal-data"> personal data </a> and <a class="tiw__link tiw__link_theme-light" target="blank" href="https://breezy .band/kz.pdf"> Trade-In terms </a>',
        kz: 'Мен <a class="tiw__link tiw__link_theme-light" target="blank" href="https://breezy.band/kz.pdf#personal-data"> жеке деректерімді </a> және < a class="tiw__link tiw__link_theme-light" target="blank" href="https://breezy.band/kz.pdf"> cауда шарттары</a>',
    },
    tiw__successfulEvaluation__checkbox_duty_head: {
        key: "tiw__successfulEvaluation__checkbox_duty_head",
        ru: "Я обязуюсь",
        en: "I commit to",
        kz: "Мен міндеттеймін",
    },
    tiw__successfulEvaluation__checkbox_duty_step1: {
        key: "tiw__successfulEvaluation__checkbox_duty_step1",
        ru: "передать устройство с тем же IMEI, который был указан при оценке;",
        en: "transfer the device with the same IMEI that was specified during the evaluation;",
        kz: "құрылғыны бағалау кезінде көрсетілген бірдей IMEI-мен тапсыру;",
    },
    tiw__successfulEvaluation__checkbox_duty_step2: {
        key: "tiw__successfulEvaluation__checkbox_duty_step2",
        ru: "передать устройство в том же состоянии, в каком оно было в момент диагностики;",
        en: "transfer the device in the same state as it was at the time of diagnosis;",
        kz: "құрылғыны диагностика кезінде болған күйінде тапсыру;",
    },
    tiw__successfulEvaluation__checkbox_duty_step3: {
        key: "tiw__successfulEvaluation__checkbox_duty_step3",
        ru: "выйти из всех аккаунтов к моменту передачи устройства.",
        en: "log out of all accounts by the time the device is transferred.",
        kz: "құрылғыны беру кезінде барлық тіркеулік жазбалардан шығу.",
    },
    tiw__successfulEvaluation__checkbox_duty_footer: {
        key: "tiw__successfulEvaluation__checkbox_duty_footer",
        ru: "Я понимаю, что невыполнение данных условий ведет к аннулированию покупки в Trade-In",
        en: "I understand that failure to comply with these conditions leads to the cancellation of the purchase in the Trade-In",
        kz: "Мен бұл шарттарды орындамау Trade-In сатып алудан бас тартуға әкелетінін түсінемін",
    },
    tiw__successfulEvaluation__warn: {
        key: "tiw__successfulEvaluation__warn",
        ru: "<strong>Обратите внимание!</strong> Получение заказа только по удостоверению личности.",
        en: "<strong>Please note!</strong> Receiving an order only with an identity card.",
        kz: "<strong>Назар аударыңыз!</strong> Тапсырысты тек жеке куәлік бойынша ала аласыз.",
    },
    tiw__successfulEvaluation__toContract: {
        key: "tiw__successfulEvaluation__toContract",
        ru: "Получить договор по SMS",
        en: "Get a contract by SMS",
        kz: "SMS арқылы шарт алу",
    },

    // Экран "sendSmsContract"
    tiw__sendSmsContract__title: {
        key: "tiw__sendSmsContract__title",
        ru: "Осталось только подписать договор",
        en: "All that remains is to sign the contract",
        kz: "Шартқа қол қою ғана қалды",
    },
    tiw__sendSmsContract__subtitle: {
        key: "tiw__sendSmsContract__subtitle",
        ru: 'Договор успешно оформлен и ожидает подписания через SMS-сообщение. Для этого нажмите кнопку "Отправить SMS-договор"',
        en: 'The contract has been successfully executed and is awaiting signing via SMS. To do this, click the "Send SMS agreement" button',
        kz: 'Шарт сәтті ресімделді және SMS хабарлама арқылы қол қоюды күтуде. Ол үшін "SMS-шарт жіберу" батырмасын басыңыз',
    },
    tiw__sendSmsContract__sendContract: {
        key: "tiw__sendSmsContract__sendAgreement",
        ru: "Отправить SMS-договор",
        en: "Send an SMS contract",
        kz: "SMS-шартты жіберу",
    },

    // Экран "addToCart"
    tiw__addToCart__title: {
        key: "tiw__addToCart__title",
        ru: "Ура! Все готово!",
        en: "Hurray! Everything is ready!",
        kz: "Алақай! Барлығы дайын!",
    },
    tiw__addToCart__subtitle: {
        key: "tiw__addToCart__subtitle",
        ru: 'Договор отправлен на номер <strong>[[NUMBER]]</strong> и ожидает Вашего подписания через SMS сообщение. Нажимая на кнопку "Добавить в корзину" вы подтверждаете, что подписали договор',
        en: 'The contract has been successfully sent to the number <strong>[[NUMBER]]</strong> and is awaiting your signing via SMS message. By clicking on the "Add to Cart" button, you confirm that you have signed the contract',
        kz: 'Шарт <strong>[[NUMBER]]</strong> нөміріне сәтті жіберілді және SMS хабарлама арқылы қол қоюыңызды күтеді. "Себетке салу" батырмасын басу арқылы сіз шартқа қол қойғаныңызды растайсыз',
    },
    tiw__addToCart__notContract: {
        key: "tiw__addToCart__notContract",
        ru: "Не получили договор? Отправьте договор еще раз",
        en: "Have you not received the contract? Send the contract again",
        kz: "Шартты алмадыңыз ба? Шартты тағы бір рет жіберіңіз",
    },
    tiw__addToCart__sendAgain: {
        key: "tiw__addToCart__sendAgain",
        ru: "Отправить SMS-договор еще раз",
        en: "Send the SMS contract again",
        kz: "SMS-шартын тағы бір рет жіберу",
    },
    tiw__addToCart__warn: {
        key: "tiw__addToCart__warn",
        ru: "Договор не был подписан",
        en: "The contract was not signed",
        kz: "Шартқа қол қойылмаған",
    },
    tiw__addToCart__addToCart: {
        key: "tiw__addToCart__addToCart",
        ru: "Добавить в корзину",
        en: "Add to Cart",
        kz: "Себетке қосу",
    },
};

export default TRANSLATIONS;
