<template>
    <Modal class="tiw__step__enterImei">
        <template #header>
            <h2>
                {{ useTranslate("tiw__enterImei__title") }}
            </h2>
        </template>

        <div>
            <HandedDevice
                :device-chain="ChooseDeviceStore.deviceChain"
                :device-state="HowYouRateDeviceStore.rate"
                :device-preview="ChooseDeviceStore.deviceImagePreview"
                :device-grade="null"
                :device-no-grade="false"
                :device-terminated="false"
                :on-edit="onEditHandler"
            />
            <form name="enterImeiForm">
                <!-- Поле "IMEI" -->
                <Field :is-error="!EnterImeiValidation.status">
                    <template #hintTop>
                        <span>
                            {{ useTranslate("tiw__enterImei__imei") }}
                        </span>
                    </template>
                    <template #body>
                        <Input
                            v-model="ChooseDeviceStore.field_imei.value"
                            type="text"
                            :placeholder="useTranslate('tiw__enterImei__imei')"
                            name="imei"
                            maxlength="15"
                            inputmode="numeric"
                            :disabled="loadingToNext"
                        />
                    </template>
                    <template v-if="!EnterImeiValidation.status" #hintBottom>
                        <span>
                            {{ EnterImeiValidation.message }}
                        </span>
                    </template>
                </Field>

                <template v-if="imeiIsNotValid">
                    <div class="tiw__step__enterImei__warn">
                        <div
                            v-if="errorMessage.modelName"
                            class="tiw__step__enterImei__warn__model"
                        >
                            <div
                                class="tiw__step__enterImei__warn__model__title"
                            >
                                {{ useTranslate("tiw__enterImei__yourModel") }}
                            </div>
                            <span
                                class="tiw__step__enterImei__warn__model__phone"
                            >
                                {{ errorMessage.modelName }}
                            </span>
                        </div>
                        {{ errorMessage.error }}
                    </div>
                    <Button
                        v-if="errorMessage.modelName"
                        type="button"
                        class="tiw__step__enterImei__btn-close"
                        @click="onEditHandler"
                    >
                        <span>{{ useTranslate("tiw__continue") }}</span>
                    </Button>
                    <Button
                        v-else
                        type="button"
                        class="tiw__step__enterImei__btn-close"
                        @click="clickToClose"
                    >
                        <span>{{ useTranslate("tiw__close") }}</span>
                    </Button>
                </template>
                <template v-else>
                    <div class="tiw_group tiw_group-sb">
                        <Button
                            color="link"
                            variant="link"
                            type="button"
                            class="tiw_fs-12"
                            @click="showInfoAboutImei"
                        >
                            <span>
                                {{
                                    useTranslate("tiw__enterImei__howFindImei")
                                }}
                            </span>
                        </Button>
                        <Button
                            type="button"
                            class="tiw__step__enterImei__btn-next"
                            :disabled="
                                !EnterImeiValidation.status || loadingToNext
                            "
                            :loading="loadingToNext"
                            @click="clickToNext"
                        >
                            <span>
                                {{ useTranslate("tiw__continue") }}
                            </span>
                        </Button>
                    </div>
                </template>

                <!-- Инфо-блок с пояснением как узнать свой IMEI -->
                <template v-if="howFindImeiBlock">
                    <div class="tiw__step__enterImei__info">
                        <h3 class="tiw__title">
                            {{ useTranslate("tiw__enterImei__howFindImei") }}
                        </h3>
                        <ul class="tiw__ul tiw__ul_disc tiw_fs-14">
                            <li
                                v-html="
                                    useTranslate(
                                        'tiw__enterImei__howFindImei_step1'
                                    )
                                "
                            />
                            <li>
                                {{
                                    useTranslate(
                                        "tiw__enterImei__howFindImei_step2"
                                    )
                                }}
                            </li>
                        </ul>
                        <figure class="tiw_tc">
                            <img
                                src="/img/iPhone-dial-imei.png"
                                alt="iPhone dial *#06#"
                            />
                        </figure>
                    </div>
                </template>
            </form>
        </div>
    </Modal>
</template>

<script lang="ts">
export default {
    name: "EnterImei",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [EnterImei]
 * Модальное окно / экран "Введите IMEI"
 */

// Components
import Modal from "@/components/modal/modal.vue";
import HandedDevice from "@/components/handedDevice/handedDevice.vue";
import Button from "@/ui/button/button.vue";
import Input from "@/ui/input/input.vue";
import Field from "@/ui/field/field.vue";

// Hooks
import { ref, computed, watch } from "vue";
import useTranslate from "@/hooks/useTranslate/useTranslate";
import useValidate from "@/hooks/useValidate";
import it4UpdateIMEI from "@/hooks/requests/it4UpdateIMEI/it4UpdateIMEI";
import useQRPath from "@/hooks/useQRPath/useQRPath";
import it4GetLoadPhotoFields from "@/hooks/requests/it4GetLoadPhotoFields/it4GetLoadPhotoFields";
import useStoreResetter from "@/hooks/useStoreResetter/useStoreResetter";
import it4GetIMEIStatus from "@/hooks/requests/it4GetIMEIStatus/it4GetIMEIStatus";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";
import { useChooseDeviceStore } from "@/stores/chooseDevice/chooseDevice";
import { useHowYouRateDeviceStore } from "@/stores/howYouRateDevice/howYouRateDevice";
import { useDownloadPhotosStore } from "@/stores/downloadPhotos/downloadPhotos";

const TIWStore = useTIWStore();
const ChooseDeviceStore = useChooseDeviceStore();
const HowYouRateDeviceStore = useHowYouRateDeviceStore();
const DownloadPhotosStore = useDownloadPhotosStore();

const onEditHandler = () => {
    HowYouRateDeviceStore.restoreToDefault();
    ChooseDeviceStore.restoreToDefault();
    TIWStore.switchStep("chooseDevice");
};

const EnterImeiValidation = computed(() => {
    if (!ChooseDeviceStore.field_imei.value.length) {
        return {
            status: true,
            message: "ok",
        };
    }
    const valiDate = useValidate({
        target: "imei",
        value: ChooseDeviceStore.field_imei.value,
    });
    return valiDate;
});

const howFindImeiBlock = ref<boolean>(false);
const imeiIsNotValid = ref<boolean>(false);
const errorMessage = ref<{ error: string; modelName?: string }>({
    error: "",
    modelName: "",
});

const showInfoAboutImei = () =>
    (howFindImeiBlock.value = !howFindImeiBlock.value);

const loadingToNext = ref<boolean>(false);

watch(ChooseDeviceStore.field_imei, () => {
    if (
        ChooseDeviceStore.field_imei.value &&
        EnterImeiValidation.value.status
    ) {
        imeiIsNotValid.value = false;
    }
});

const clickToClose = async () => {
    TIWStore.closeWidget();
    useStoreResetter();
    TIWStore.INJECTED_TIW.current_api.onDrop();
};

const interval = ref();

const getImeiExecuter = async (id: string) => {
    const imeiData = await it4GetIMEIStatus({
        token: TIWStore.WIDGET_DATA.token, // "d7bd115c-e845-4381-8f11-8044cbc11dfe",
        id, // "c6d7acf4-4657-4b79-b7c2-5e941fc3760d"
    });

    if (imeiData.status === "IN_PROGRESS") return;

    if (
        imeiData.result.imeiValidation.valid ||
        imeiData.result.imeiValidation.unavailable
    ) {
        await useQRPath();

        // Для экрана "Загрузите фото сдаваемого устройства" - загрузить поля для загрузки фото
        const loadPhotoFieldsData = await it4GetLoadPhotoFields({
            deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId,
            token: TIWStore.WIDGET_DATA.token,
            lang: TIWStore.INJECTED_TIW.current_settings.locale,
        });

        DownloadPhotosStore.fieldsLineData = loadPhotoFieldsData;

        loadingToNext.value = false;

        clearInterval(interval.value);

        ChooseDeviceStore.field_imei.savedValue =
            ChooseDeviceStore.field_imei.value;

        TIWStore.switchStep("downloadPhotos");
    } else {
        /**
            "1-й экран 'К сожалению устройство не принимается'"
                "unavailable": !true,
                "valid": false,
                "found": false,

            "2-й экран 'Проверьте правильность IMEI'"
                "unavailable": !true,
                "modelName": null || '' [1 условие]

            "3-й экран 'Пожалуйста перепройдите проверку'"
                "unavailable": !true,
                "valid": false,
                "found": true,
        */

        if (!imeiData.result.imeiValidation.modelName) {
            console.log("UNCORRECT");
            errorMessage.value = {
                error: useTranslate("tiw__enterImei__imei_uncorrect"),
            };
        } else {
            if (
                !imeiData.result.imeiValidation.valid &&
                !imeiData.result.imeiValidation.found
            ) {
                console.log("NOT ACCEPTED");
                errorMessage.value = {
                    error: useTranslate("tiw__enterImei__imei_notAccepted"),
                };
            }

            if (
                !imeiData.result.imeiValidation.valid &&
                imeiData.result.imeiValidation.found
            ) {
                console.log("RE EVALUATE");
                errorMessage.value = {
                    error: useTranslate("tiw__enterImei__imei_reEvaluate"),
                    modelName: imeiData.result.imeiValidation.modelName,
                };
            }
        }
        imeiIsNotValid.value = true;
        loadingToNext.value = false;
        clearInterval(interval.value);
    }
};

const clickToNext = async () => {
    // Это типо если был шаг "назад" т.к. имей уже был
    // успешно проверен
    if (
        ChooseDeviceStore.field_imei.value ===
        ChooseDeviceStore.field_imei.savedValue
    ) {
        TIWStore.switchStep("downloadPhotos");
        return;
    }

    loadingToNext.value = true;

    const imeiData = await it4UpdateIMEI({
        article: TIWStore.INJECTED_GOOD.article, // артикул устройства со страницы партнера
        token: TIWStore.WIDGET_DATA.token, // "d7bd115c-e845-4381-8f11-8044cbc11dfe",
        imei: ChooseDeviceStore.field_imei.value, // IMEI устройства
        deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId, // deviceID сдаваемого устройства
    });

    interval.value = setInterval(async () => {
        await getImeiExecuter(imeiData.id);
    }, 5000);
};
</script>

<style lang="scss" src="./enterImei.scss"></style>
