// Types
import type {
    I_OperatorExecuting,
    I_OperatorExecuting__Statuses,
    I_OperatorExecuting__Statuses_Data,
} from "@/stores/operatorExecuting/operatorExecuting.types";

// Hooks
import useTranslate from "@/hooks/useTranslate/useTranslate";

/** Получить переводы статусов обработки фотографий оператором */
export default function useExecutingStatus(
    status: I_OperatorExecuting__Statuses
): I_OperatorExecuting__Statuses_Data {
    const statusText: I_OperatorExecuting["statusText"] = {
        WAITING: {
            header: useTranslate("tiw__operatorExecuting__title_loading"),
            message: useTranslate("tiw__operatorExecuting__message_loading"),
        },
        GRADING: {
            header: useTranslate("tiw__operatorExecuting__title_processing"),
            message: useTranslate("tiw__operatorExecuting__message_loading"),
        },
        PROCESSED: {
            header: useTranslate("tiw__operatorExecuting__title_done"),
            message: useTranslate("tiw__operatorExecuting__message_loading"),
        },
    };

    return statusText[status];
}
