<template>
    <section class="tiw__step-wrapper">
        <Button variant="unset" class="tiw__step-wrapper__close">
            <CloseIcon />
        </Button>
        <CloseMenu />
        <div class="tiw__step-wrapper__header">
            <slot name="header"></slot>
        </div>
        <div class="tiw__step-wrapper__content">
            <slot></slot>
        </div>

        <div class="tiw__step-wrapper__crunch">
            <ChatIcon />
            <span>
                <span>
                    {{ useTranslate("tiw__crunch__needHelp") }}
                </span>
                <Button
                    type="button"
                    variant="link"
                    color="link"
                    @click="openCrunch"
                >
                    <span v-html="useTranslate('tiw__crunch__contactUs')" />
                </Button>
            </span>
        </div>

        <footer class="tiw__step-wrapper__footer">
            <span>Powered by</span>
            <BreezyIcon />
        </footer>
    </section>
</template>

<script lang="ts">
export default {
    name: "ModalComponent",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [Modal]
 * Модальное окно / обертка
 */

// Components
import Button from "@/ui/button/button.vue";
import CloseMenu from "@/components/closeMenu/closeMenu.vue";

// Icon
import CloseIcon from "@/ui/icons/close.vue";
import ChatIcon from "@/ui/icons/chat.vue";
import BreezyIcon from "@/ui/icons/breezy.vue";
import useTranslate from "~/tiw/hooks/useTranslate/useTranslate";

// Store
// import { useTIWStore } from "@/stores/tiw/tiw";

// const TIWStore = useTIWStore();

const openCrunch = () => {
    HelpCrunch("openChat");
};
</script>

<style lang="scss" src="./modal.scss"></style>
