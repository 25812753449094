<template>
    <div class="tiw__timer">
        <LoaderIcon />
        <span
            :class="[
                'tiw__timer__time',
                { tiw__timer__time_pause: props.isPause },
            ]"
        >
            <span class="tiw__timer__minutes">
                {{ timerData.minutes }}
            </span>
            <span class="tiw__timer__divider">:</span>
            <span class="tiw__timer__seconds">
                {{ timerData.seconds }}
            </span>
        </span>
    </div>
</template>

<script lang="ts">
export default {
    name: "TimerComponent",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [Timer]
 * Таймер обработки фото оператором
 */

// Hooks
import { ref, reactive, onMounted, onUnmounted, watch, defineEmits } from "vue";

// Types
import type { I_Timer, I_TimerData } from "./timer.types.ts";

// Icons
import LoaderIcon from "@/ui/icons/loader.vue";

const props = withDefaults(defineProps<Partial<I_Timer>>(), {
    minutes: 5,
    seconds: 0,
});

/** Начальное и остаточное время отсчета в миллисекундах */
const startCountFrom = ref<number>((props.minutes * 60 + props.seconds) * 1000);

/** Процент пройденного времени отсчета */
const percent = ref<number>(0);

/** Выводимый отсчет */
const timerData = reactive<I_TimerData>({
    minutes: "05",
    seconds: "00",
});

/** Идентификатор интервальной функции */
const intervalCounterId = ref();

/** Интервал эмита через каждые props.emitPerSeconds */
const intervalCounterEmit = ref<number>(1);

/** Функция обратного отсчета */
const counter = () => {
    const millisecondsInOneSecond = 1000;

    // Присвоить снова 5 минут
    if (startCountFrom.value === 0) {
        startCountFrom.value = 300000;
    }

    startCountFrom.value -= 1000;

    const millisecondsInOneMinute = millisecondsInOneSecond * 60;
    const millisecondsInOneHour = millisecondsInOneMinute * 60;

    const remainderDifferenceInMinutes =
        (startCountFrom.value % millisecondsInOneHour) /
        millisecondsInOneMinute;
    const remainderDifferenceInSeconds =
        (startCountFrom.value % millisecondsInOneMinute) /
        millisecondsInOneSecond;

    const remainingMinutes = Math.floor(remainderDifferenceInMinutes);
    const remainingSeconds = Math.floor(remainderDifferenceInSeconds);

    timerData.minutes =
        remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes;
    timerData.seconds =
        remainingSeconds < 10
            ? `0${remainderDifferenceInSeconds}`
            : remainderDifferenceInSeconds;

    percent.value = Math.floor(
        100 -
            (startCountFrom.value /
                ((props.minutes * 60 + props.seconds) * 1000)) *
                100
    );
};

const emit = defineEmits(["emitInterval", "emitSecond"]);

onMounted(() => {
    intervalCounterId.value = setInterval(() => {
        counter();
    }, 1000);
});

onUnmounted(() => {
    clearInterval(intervalCounterId.value);
});

watch(timerData, () => {
    emit("emitSecond", percent.value);
    if (intervalCounterEmit.value === props.emitPerSeconds) {
        emit("emitInterval", timerData);
        intervalCounterEmit.value = 0;
    }
    intervalCounterEmit.value++;
});

watch(props, () => {
    if (props.isPause) {
        clearInterval(intervalCounterId.value);
    }
});

const restart = () => {
    console.log("RESTART");
    console.log("SET_MINUTES", props.minutes);
    startCountFrom.value = props.minutes * 60 * 1000;
};

defineExpose({
    restart,
});
</script>

<style lang="scss" src="./timer.scss"></style>
