// Data
import { I_AXIOS } from "@/hooks/requests/configRequest.model";

// Hooks
import it4GetUploadId from "@/hooks/requests/it4GetUploadId/it4GetUploadId";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";
import { useHowYouRateDeviceStore } from "@/stores/howYouRateDevice/howYouRateDevice";
import { useDownloadPhotosStore } from "@/stores/downloadPhotos/downloadPhotos";
import { useEvaluationResultStore } from "@/stores/evaluationResult/evaluationResult";
import { info } from "@/helpers/consoleColors";

/** Сгенерировать путь для QR-кода */
export default async function useQRPath(): Promise<void> {
    const QRPath = new URL(I_AXIOS.IT4_HOST_PREVIEW + I_AXIOS.IT4_QR_ENDPOINT);

    const TIWStore = useTIWStore();
    const HowYouRateDeviceStore = useHowYouRateDeviceStore();
    const DownloadPhotosStore = useDownloadPhotosStore();
    const EvaluationResultStore = useEvaluationResultStore();

    const uploadIdData = await it4GetUploadId({
        token: TIWStore.WIDGET_DATA.token,
        deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId,
    });

    QRPath.searchParams.set("token", uploadIdData.uploadId);
    QRPath.searchParams.set(
        "lang",
        TIWStore.INJECTED_TIW.current_settings.locale
    );

    DownloadPhotosStore.qrPath =
        I_AXIOS.QR_API_IT4 + encodeURIComponent(QRPath.toString());
    EvaluationResultStore.qrPath = DownloadPhotosStore.qrPath;

    console.log("%c[TIWidget] -> [QR-link]", info, QRPath.toString());
}
