/** Грейд сдаваемого устройства */
export type I_GRADES = "A+" | "A" | "B" | "C" | "D" | "E" | "F" | "N" | "Q";

/** Описание грейда сдаваемого устройства */
interface I_GRADES_INFO {
    // stateInfo: string; // Супер-краткое описание грейда
    // description: string; // Краткое описание грейда
    gradeCode: I_GRADES; // Коды грейдов
}

/** Варианты грейдов сдаваемого устройства */
export const GRADES_DATA: Record<I_GRADES, I_GRADES_INFO> = {
    Q: {
        gradeCode: "Q",
    },
    "A+": {
        gradeCode: "A+",
    },
    A: {
        gradeCode: "A",
    },
    B: {
        gradeCode: "B",
    },
    C: {
        gradeCode: "C",
    },
    D: {
        gradeCode: "D",
    },
    E: {
        gradeCode: "E",
    },
    // TODO Держать в уме что может добавиться этот грейд
    F: {
        // stateInfo: useTranslate("tiw__grades__f_stateInfo"),
        // description: useTranslate("tiw__grades__f_description"),
        gradeCode: "F",
    },
    // TODO Держать в уме что может добавиться этот грейд
    N: {
        // stateInfo: useTranslate("tiw__grades__n_stateInfo"),
        // description: useTranslate("tiw__grades__n_description"),
        gradeCode: "N",
    },
};
