<template>
    <!-- * Иконка 'Свернуть' -->
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        :class="['tiw__icon']"
        v-bind="$attrs"
    >
        <path
            d="M15.8156 18H4.18141C3.60287 18 3.04802 17.7702 2.63892 17.3611C2.22983 16.952 2 16.3971 2 15.8186V4.18439C2 3.60584 2.22983 3.05099 2.63892 2.6419C3.04802 2.2328 3.60287 2.00298 4.18141 2.00298H8.54424C8.73709 2.00298 8.92204 2.07958 9.0584 2.21595C9.19477 2.35231 9.27138 2.53726 9.27138 2.73011C9.27138 2.92296 9.19477 3.10791 9.0584 3.24428C8.92204 3.38064 8.73709 3.45725 8.54424 3.45725H4.18141C3.98856 3.45725 3.80361 3.53386 3.66725 3.67022C3.53088 3.80659 3.45428 3.99154 3.45428 4.18439V15.8186C3.45428 16.0114 3.53088 16.1964 3.66725 16.3328C3.80361 16.4691 3.98856 16.5457 4.18141 16.5457H15.8156C16.0085 16.5457 16.1934 16.4691 16.3298 16.3328C16.4661 16.1964 16.5428 16.0114 16.5428 15.8186V11.4558C16.5428 11.2629 16.6194 11.078 16.7557 10.9416C16.8921 10.8052 17.077 10.7286 17.2699 10.7286C17.4627 10.7286 17.6477 10.8052 17.7841 10.9416C17.9204 11.078 17.997 11.2629 17.997 11.4558V15.8186C17.997 16.3971 17.7672 16.952 17.3581 17.3611C16.949 17.7702 16.3942 18 15.8156 18ZM12.9071 12.1829H8.54424C8.35139 12.1829 8.16644 12.1063 8.03007 11.9699C7.89371 11.8336 7.8171 11.6486 7.8171 11.4558V7.09294C7.8171 6.90009 7.89371 6.71514 8.03007 6.57877C8.16644 6.44241 8.35139 6.3658 8.54424 6.3658C8.73709 6.3658 8.92204 6.44241 9.0584 6.57877C9.19477 6.71514 9.27138 6.90009 9.27138 7.09294V9.70336L16.7536 2.21385C16.8905 2.07692 17.0762 2 17.2699 2C17.4635 2 17.6492 2.07692 17.7862 2.21385C17.9231 2.35077 18 2.53647 18 2.73011C18 2.92375 17.9231 3.10946 17.7862 3.24638L10.3039 10.7286H12.9071C13.0999 10.7286 13.2849 10.8052 13.4212 10.9416C13.5576 11.078 13.6342 11.2629 13.6342 11.4558C13.6342 11.6486 13.5576 11.8336 13.4212 11.9699C13.2849 12.1063 13.0999 12.1829 12.9071 12.1829Z"
            fill="#808D9A"
        />
    </svg>
</template>

<script lang="ts">
export default {
    name: "InternalIcon",
};
</script>
