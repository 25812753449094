// Hooks
import { defineStore } from "pinia";
import { computed, reactive, ref, watch } from "vue";
import apiGetDeps from "@/hooks/requests/apiGetDeps/apiGetDeps.js";
import apiGetModels from "@/hooks/requests/apiGetModels/apiGetModels.js";

// Types
import type {
    I_ManualCharacteristicsData,
    I_field_brand,
    I_field_category,
    I_field_model,
} from "./buyDevice.types.ts";

// Stores
import { useTIWStore } from "@/stores/tiw/tiw";

/**
 * * 🆂🆃🅾🆁🅴 [Buy Device]
 * */
export const useBuyDeviceStore = defineStore("buyDevice", () => {
    const TIWStore = useTIWStore();

    // |-------|
    // | STATE |
    // |-------|

    /** Значения поля Brand для fetch-отправки формы */
    const field_brand = reactive<I_field_brand>({
        value: null,
        data: [],
    });

    /** Значения поля Category для fetch-отправки формы */
    const field_category = reactive<I_field_category>({
        value: null,
        data: [],
    });

    /** Значения поля Model для fetch-отправки формы */
    const field_model = reactive<I_field_model>({
        value: null,
        data: [],
    });

    /** Данные полей для ручного ввода характеристик */
    const manualCharacteristicsData = reactive<I_ManualCharacteristicsData>({
        brand: [],
        brand_categories: [],
        category: [],
        model: [],
        products: [],
    });

    /** Изображение "Превью" выбранного устройства для следующего шага формы */
    const deviceImagePreview = ref<string | null>("/img/ic_phone.png");

    // |----------|
    // | COMPUTED |
    // |----------|

    /** Выбраны ли все Select ручного ввода характеристик */
    const isSelectedManualFiles = computed<boolean>(() => {
        return [field_brand, field_category, field_model].every((field) =>
            Boolean(field.value)
        );
    });

    /**
     * Цепочка характеристик устройства
     * @example ["Apple", "iPhone", "12 Pro Max"]
     */
    const deviceChain = computed<string[]>(() => {
        return [field_brand.value.name, field_category.value.name];
    });

    // |---------|
    // | METHODS |
    // |---------|

    /** Загрузить Бренды */
    const fetchBrands = async () => {
        const brandsData = await apiGetDeps();
        console.log(brandsData.data.brands);

        field_brand.data = brandsData.data.brands;

        manualCharacteristicsData.brand = brandsData.data.brands;
        manualCharacteristicsData.brand_categories =
            brandsData.data.brands_categories;
        manualCharacteristicsData.category = brandsData.data.categories;
    };

    /** Загрузить Модели */
    const fetchModels = async () => {
        const modelsData = await apiGetModels({
            brand_id: field_brand.value.brand_id,
            category_id: field_category.value.category_id,
        });

        console.log(modelsData.data);

        manualCharacteristicsData.model = modelsData.data;
        field_model.data = modelsData.data;
    };

    /** Сбросить значения STORE на по-умолчанию */
    const restoreToDefault = () => {
        [field_category, field_model].forEach((field) => {
            field.value = null;
            field.data = [];
        });

        field_brand.value = null;
        deviceImagePreview.value = null;
    };

    // |----------|
    // | WATCHERS |
    // |----------|

    // ЛОКАЛЬНО ВЫЧИСЛЯЕТСЯ ПОЛЕ "КАТЕГОРИЯ" НА ОСНОВАНИИ ПОЛЯ "БРЕНД"
    watch(field_brand, () => {
        if (field_brand.value) {
            const filteredBrandCategoriesByBrand =
                manualCharacteristicsData.brand_categories.filter(
                    (el) => el.brand_id === field_brand.value.brand_id
                );

            const filteredCategory = filteredBrandCategoriesByBrand.map(
                (cat_brand) => {
                    const data = manualCharacteristicsData.category.filter(
                        (cat) => cat_brand.category_id === cat.category_id
                    );
                    return data[0];
                }
            );

            console.log(filteredCategory);

            field_category.data = filteredCategory;
            field_category.value = null;
        } else {
            field_category.value = null;
            field_category.data = [];
        }
    });

    // ВЫЧИСЛЯЕТСЯ ПОЛЕ "МОДЕЛЬ" НА ОСНОВАНИИ ПОЛЯ "БРЕНД" + "КАТЕГОРИЯ"
    watch(field_category, async () => {
        if (field_brand.value && field_category.value) {
            await fetchModels();
            field_model.value = null;
        } else {
            field_model.value = null;
            field_model.data = [];
        }
    });

    // ЛОКАЛЬНО ВЫЧИСЛЯЕТСЯ ПОЛЕ "ПАМЯТЬ / МОДЕЛЬ / ГОД" НА ОСНОВАНИИ ПОЛЯ "МОДЕЛЬ"
    watch(field_model, async () => {
        if (field_brand.value && field_category.value && field_model.value) {
            TIWStore.INJECTED_GOOD = {
                article: field_model.value.model_id.toString(),
                panelTitle: deviceChain.value.join(" "),
                panelDevicePicture: deviceImagePreview.value,
                panelPrice: "700 000",
            };
        }
    });

    return {
        // * 🆂🆃🅾🆁🅴
        field_brand,
        field_category,
        field_model,

        manualCharacteristicsData,
        deviceImagePreview,

        // * 🅲🅾🅼🅿🆄🆃🅴🅳
        isSelectedManualFiles,
        deviceChain,

        // * 🅼🅴🆃🅷🅾🅳🆂
        fetchBrands,
        restoreToDefault,
    };
});
