<template>
    <!-- * Иконка 'Награда' -->
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        :class="['tiw__icon']"
        v-bind="$attrs"
    >
        <g clip-path="url(#clip0_26953_46791)">
            <path
                d="M2.23141 21.4871C2.41976 21.6929 2.71189 21.7678 2.97608 21.6782L5.9862 20.6561L7.24462 23.5752C7.35534 23.832 7.60777 23.9989 7.88743 24H7.89033C8.16887 24 8.42125 23.8356 8.5337 23.5805L10.8795 18.2597C11.2468 18.3048 11.6206 18.3284 11.9999 18.3284C12.3792 18.3284 12.7531 18.3048 13.1204 18.2597L15.4661 23.5805C15.5785 23.8356 15.831 24 16.1096 24H16.1125C16.3922 23.9989 16.6445 23.832 16.7553 23.5752L18.0137 20.6561L21.0239 21.6782C21.2878 21.7678 21.5802 21.6929 21.7685 21.4871C21.9569 21.2814 22.0059 20.9837 21.8932 20.7285L19.2529 14.7438C20.4361 13.2029 21.1407 11.2761 21.1407 9.18763C21.1407 4.12154 17.0402 0 12 0C6.95976 0 2.85922 4.12154 2.85922 9.18763C2.85922 11.2761 3.5638 13.2029 4.74704 14.7438L2.10672 20.7285C1.99413 20.9837 2.04302 21.2814 2.23141 21.4871ZM19.9606 19.832L17.8511 19.1157C17.504 18.9978 17.1245 19.1665 16.9793 19.5032L16.1023 21.5375L14.5304 17.9718C15.9244 17.5696 17.1823 16.8436 18.2176 15.8812L19.9606 19.832ZM4.26549 9.18763C4.26549 4.89696 7.73517 1.40627 12 1.40627C16.2648 1.40627 19.7344 4.89696 19.7344 9.18763C19.7344 13.4524 16.2648 16.9221 12 16.9221C7.73517 16.9221 4.26549 13.4524 4.26549 9.18763ZM5.78224 15.8812C6.81763 16.8436 8.07549 17.5696 9.46953 17.9718L7.89755 21.5374L7.0206 19.5031C6.87543 19.1664 6.49597 18.9978 6.14885 19.1157L4.03931 19.832L5.78224 15.8812Z"
                fill="#808D9A"
            />
            <path
                d="M9.23902 10.0849L8.82788 12.4818C8.78264 12.7455 8.89106 13.0122 9.10758 13.1695C9.32373 13.3265 9.61079 13.3477 9.84808 13.223L12.0007 12.0913L14.1532 13.223C14.3901 13.3475 14.6773 13.3268 14.8937 13.1695C15.1102 13.0122 15.2187 12.7456 15.1734 12.4818L14.7623 10.0849L16.5038 8.38735C16.6954 8.20055 16.7644 7.92113 16.6817 7.66659C16.5989 7.41206 16.3789 7.22653 16.1141 7.18804L13.7074 6.83835L12.6312 4.6576C12.5127 4.4176 12.2683 4.26562 12.0007 4.26562C11.733 4.26562 11.4886 4.41755 11.3701 4.6576L10.2939 6.83835L7.88722 7.18804C7.62237 7.22653 7.40229 7.41206 7.3196 7.66659C7.23687 7.92113 7.30587 8.20055 7.4975 8.3874L9.23902 10.0849ZM10.8619 8.17679C11.0909 8.14351 11.2889 7.99969 11.3913 7.79213L12.0007 6.55747L12.61 7.79213C12.7124 7.99964 12.9104 8.14351 13.1394 8.17679L14.5019 8.37479L13.516 9.33584C13.3503 9.49737 13.2747 9.73011 13.3138 9.9582L13.5466 11.3153L12.3278 10.6746C12.123 10.5669 11.8783 10.5668 11.6735 10.6746L10.4547 11.3153L10.6875 9.9582C10.7266 9.73011 10.651 9.49737 10.4853 9.33584L9.49928 8.37479L10.8619 8.17679Z"
                fill="#6959CB"
            />
        </g>
        <defs>
            <clipPath id="clip0_26953_46791">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script lang="ts">
export default {
    name: "AwardIcon",
};
</script>
