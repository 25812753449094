// Hooks
import { defineStore } from "pinia";
import { ref, inject, watch } from "vue";
import crazyInit from "@/helpers/crazyInit";

// Types
import type { I_TIW, I_INJECTED_TIW } from "./tiw.types";

/**
 * * 🆂🆃🅾🆁🅴 [TIW STORE] Самый высокоуровневый STORE приложения
 * */
export const useTIWStore = defineStore("TIW", () => {
    // |---------------|--------------------|
    // | INJECTED DATA | APP INITIALIZATION |
    // |---------------|--------------------|

    /**
     * Инициализированный класс TradeInWidget
     */
    const INJECTED_TIW = inject<I_INJECTED_TIW>("widget");

    /**
     * Инициализированный instance текущего приложения
     */
    // const INJECTED_APP = inject("appInstance");

    // |-------|-----------------------------------------------------------------|
    // | STATE | ⓘ См. https://pinia-ru.netlify.app/core-concepts/#setup-stores |
    // |-------|-----------------------------------------------------------------|

    /** Предыдущий шаг формы */
    const PREVIOUS_STEP = ref<I_TIW["PREVIOUS_STEP"]>(null);

    /** Текущий шаг формы */
    const CURRENT_STEP = ref<I_TIW["CURRENT_STEP"]>("chooseDevice");

    /** Закрыть / Открыть виджет / true - закрыт */
    const IS_CLOSE = ref<I_TIW["IS_CLOSE"]>(true);

    /** Состояние действия анимации Закрытия */
    const IS_TRANSITION = ref<I_TIW["IS_TRANSITION"]>(false);

    /** Свернуть / Показать виджет / true - свернут  */
    const IS_ROLL = ref<I_TIW["IS_ROLL"]>(false);

    /**  Данные виджета (переводы, доступные локали и т.д.) */
    const WIDGET_DATA = ref<I_TIW["WIDGET_DATA"]>(null);

    // |----------|
    // | COMPUTED |
    // |----------|

    /** Выкупаемое устройство */
    const INJECTED_GOOD = ref<I_TIW["INJECTED_GOOD"]>(null);

    // |---------|
    // | METHODS |
    // |---------|

    /** Переключить экраны (шаги форм) виджета */
    const switchStep = (toStep: I_TIW["CURRENT_STEP"]) => {
        if (toStep === CURRENT_STEP.value) return;

        if (toStep === "chooseDevice") {
            PREVIOUS_STEP.value = null;
            CURRENT_STEP.value = toStep;
            return;
        }

        PREVIOUS_STEP.value = CURRENT_STEP.value;
        CURRENT_STEP.value = toStep;
    };

    /** ЗАКРЫТЬ модальное окно виджета */
    const closeWidget = () => {
        if (IS_ROLL.value) return;

        IS_TRANSITION.value = true;
        setTimeout(() => {
            IS_TRANSITION.value = false;
        }, 150);

        // Reset
        IS_CLOSE.value = true;
        INJECTED_GOOD.value = null;
        CURRENT_STEP.value = "chooseDevice";
        PREVIOUS_STEP.value = null;
    };

    /** ОТКРЫТЬ модальное окно виджета */
    const openWidget = () => {
        IS_TRANSITION.value = true;
        setTimeout(() => {
            IS_TRANSITION.value = false;
        }, 150);
        IS_CLOSE.value = false;
    };

    /** СВЕРНУТЬ модальное окно виджета */
    const rollDownWidget = () => {
        IS_ROLL.value = true;
    };

    /** РАЗВЕРНУТЬ модальное окно виджета */
    const rollUpWidget = () => {
        IS_ROLL.value = false;
    };

    // |----------|
    // | WATCHERS |
    // |----------|

    watch(IS_CLOSE, () => {
        document.body.style.overflow = !IS_CLOSE.value ? "hidden" : "auto";
    });

    watch(IS_ROLL, () => {
        document.body.style.overflow = IS_ROLL.value ? "auto" : "hidden";
    });

    // |---------------|---------------------------------|
    // | INJECTED DATA | APP ASSIGNMENT EXTERNAL METHODS |
    // |---------------|---------------------------------|

    INJECTED_TIW.tradeInWithGood = (calculatedDeviceData) => {
        const valueAdapter = {
            article: null,
            panelTitle: null,
            panelPrice: null,
            panelDevicePicture: null,
        } as I_TIW["INJECTED_GOOD"];

        valueAdapter.article = calculatedDeviceData.article.toString();
        valueAdapter.panelTitle = calculatedDeviceData.name;
        valueAdapter.panelPrice = calculatedDeviceData.price;
        valueAdapter.panelDevicePicture = calculatedDeviceData.image;

        INJECTED_GOOD.value = valueAdapter;

        openWidget();
    };

    INJECTED_TIW.crazyInit = async (calculatedDeviceData) => {
        const valueAdapter = {
            article: null,
            panelTitle: null,
            panelPrice: null,
            panelDevicePicture: null,
        } as I_TIW["INJECTED_GOOD"];

        valueAdapter.article = calculatedDeviceData.article.toString();
        valueAdapter.panelTitle = calculatedDeviceData.name;
        valueAdapter.panelPrice = calculatedDeviceData.price;
        valueAdapter.panelDevicePicture = calculatedDeviceData.image;

        INJECTED_GOOD.value = valueAdapter;

        await crazyInit();
        switchStep("downloadPhotos");
        openWidget();
    };

    // INJECTED_TIW.tradeInFromUser = () => {
    //     CURRENT_STEP.value = "buyDevice";

    //     openWidget();
    // };

    return {
        // * 🆂🆃🅾🆁🅴
        PREVIOUS_STEP,
        CURRENT_STEP,
        IS_ROLL,
        IS_CLOSE,
        IS_TRANSITION,
        WIDGET_DATA,

        // * 🅲🅾🅼🅿🆄🆃🅴🅳

        // * 🅸🅽🅹🅴🅲🆃🅴🅳
        INJECTED_TIW,
        INJECTED_GOOD,

        // * 🅼🅴🆃🅷🅾🅳🆂
        switchStep,
        openWidget,
        closeWidget,
        rollUpWidget,
        rollDownWidget,
    };
});
