// Libs
import axios from "../configRequest";

// Data
import { I_AXIOS } from "../configRequest.model";

// Types
import type {
    I_it4UpdateIMEI,
    I_it4UpdateIMEI_Return,
} from "./it4UpdateIMEI.types";

/**
 * Зарегистрировать IMEI устройства
 * 12.1 документация ru 1.0.5 (d1b82f5)
 * */
export const it4UpdateIMEI: I_it4UpdateIMEI = async (payload) => {
    const imeiData = await axios.post<I_it4UpdateIMEI_Return>(
        `${I_AXIOS.STAGE_API_HOST}/api/tin/v1/site/quest/device/update`,
        {},
        {
            headers: {
                Accept: "application/json;charset=UTF-8",
            },
            params: payload,
        }
    );
    // console.log(imeiData.data);
    return imeiData.data;
};

export default it4UpdateIMEI;
