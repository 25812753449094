// Libs
import axios from "../configRequest";

// Data
import { I_AXIOS } from "../configRequest.model";

// Types
import type {
    I_it4GetLoadPhotoFields,
    I_it4GetLoadPhotoFields_Return,
} from "./it4GetLoadPhotoFields.types";

/**
 * Получить наименования Полей для загрузки фото
 * 15.1 документация ru 1.0.5 (d1b82f5)
 * */
export const it4GetLoadPhotoFields: I_it4GetLoadPhotoFields = async ({
    deviceId,
    token,
    lang,
    mode,
}) => {
    const loadPhotoFieldsData = await axios.get<
        I_it4GetLoadPhotoFields_Return["data"]
    >(`${I_AXIOS.STAGE_API_HOST}/api/tin/v1/site/quest/photos/${deviceId}`, {
        headers: {
            Accept: "application/json;charset=UTF-8",
        },
        params: {
            token,
            lang,
            mode: !mode ? 0 : mode,
        },
    });
    // console.log(loadPhotoFieldsData.data);
    return loadPhotoFieldsData.data;
};

export default it4GetLoadPhotoFields;
