// Store
import { useBuyDeviceStore } from "@/stores/buyDevice/buyDevice";
import { useChooseDeviceStore } from "@/stores/chooseDevice/chooseDevice";
import { useHowYouRateDeviceStore } from "@/stores/howYouRateDevice/howYouRateDevice";
import { useDownloadPhotosStore } from "@/stores/downloadPhotos/downloadPhotos";
import { useOperatorExecutingStore } from "@/stores/operatorExecuting/operatorExecuting";
import { useSuccessfulEvaluationStore } from "@/stores/successfulEvaluation/successfulEvaluation";
import { useEvaluationResultStore } from "@/stores/evaluationResult/evaluationResult";

/** Сбросить STORE Приложения */
export default function useStoreResetter(): void {
    const BuyDeviceStore = useBuyDeviceStore();
    const ChooseDeviceStore = useChooseDeviceStore();
    const HowYouRateDeviceStore = useHowYouRateDeviceStore();
    const DownloadPhotosStore = useDownloadPhotosStore();
    const OperatorExecutingStore = useOperatorExecutingStore();
    const SuccessfulEvaluationStore = useSuccessfulEvaluationStore();
    const EvaluationResultStore = useEvaluationResultStore();

    BuyDeviceStore.restoreToDefault();
    ChooseDeviceStore.restoreToDefault();
    HowYouRateDeviceStore.restoreToDefault();
    DownloadPhotosStore.restoreToDefault();
    OperatorExecutingStore.restoreToDefault();
    SuccessfulEvaluationStore.restoreToDefault();
    EvaluationResultStore.restoreToDefault();
}
