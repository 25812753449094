// Libs
import axios from "../configRequest";

// Data
import { I_AXIOS } from "../configRequest.model";

// Types
import type {
    I_checkPhotoSetStatus,
    I_checkPhotoSetStatus_Return,
} from "./it4CheckPhotoSetStatus.types.ts";

/**
 * Проверить статус обработки фотосета в ИТ4
 * 15.5 документация ru 1.0.5 (d1b82f5)
 * */
const it4CheckPhotoSetStatus: I_checkPhotoSetStatus = async ({
    deviceId,
    photosetId,
    token,
    lang,
}) => {
    const checkPhotoSetData = await axios.get<
        I_checkPhotoSetStatus_Return["data"]
    >(
        `${I_AXIOS.STAGE_API_HOST}/api/tin/v1/site/quest/photoset/${deviceId}/${photosetId}`,
        {
            headers: {
                Accept: "application/json;charset=UTF-8",
            },
            params: {
                token,
                lang,
            },
        }
    );
    // console.log(checkPhotoSetData.data);
    return checkPhotoSetData.data;
};

export default it4CheckPhotoSetStatus;
