// Data
import { I_AXIOS } from "@/hooks/requests/configRequest.model";
import { FIELD_NAMES_ADAPTER } from "@/hooks/requests/it4GetLoadPhotoFields/it4GetLoadPhotoFields.model.js";

// Hooks
import { defineStore } from "pinia";
import { ref, reactive, computed, watch } from "vue";
import useTranslate from "@/hooks/useTranslate/useTranslate.js";

// Types
import type {
    I_EvaluationResult_Field,
    I_EvaluationResult_Field_Names,
    I_EvaluationResult_FieldLineData,
    I_EvaluationResult_photoSetData,
} from "./evaluationResult.types.ts";
import type {
    I_checkPhotoSetStatus_Return,
    I_checkPhotoSetStatus_Return__instructionField,
} from "@/hooks/requests/it4CheckPhotoSetStatus/it4CheckPhotoSetStatus.types.js";

/**
 * * 🆂🆃🅾🆁🅴 [Evaluation Result]
 * */
export const useEvaluationResultStore = defineStore("evaluationResult", () => {
    // |-------|
    // | STATE |
    // |-------|

    /** Значения поля "Лицевая сторона с включенным экраном" */
    const frontImei = reactive<I_EvaluationResult_Field>({
        isLoading: false,
        content: "/img/screen_front_imei.png",
        isValid: false,
        placeholder: "",
        // message: {
        //     type: "warn",
        //     text: "Чтобы уточнить грейд сделайте дополнительные фото",
        // },
        // comment: {
        //     description:
        //         "Возможный текстовый комментарий от оператора с пояснениями и причинами",
        //     list: ["Плохое освещение", "Точечные сколы"],
        // },
        wasUploaded: false,
        hideLoadButton: false,
        initialStatus: null,
    });

    /** Значения поля "Экран с информацией о состоянии батареи" */
    const frontBattery = reactive<I_EvaluationResult_Field>({
        isLoading: false,
        content: "/img/screen_front_imei.png",
        isValid: false,
        placeholder: "",
        // message: {
        //     type: "error",
        //     text: "Чтобы уточнить грейд сделайте дополнительные фото",
        // },
        // Передача полей определяет отображение элементов
        // comment: {
        //     description:
        //         "Возможный текстовый комментарий от оператора с пояснениями и причинами",
        //     list: ["Плохое освещение", "Точечные сколы"],
        // },
        wasUploaded: false,
        initialStatus: null,
        hideLoadButton: false,
    });

    /** Значения поля "Лицевая сторона" */
    const front = reactive<I_EvaluationResult_Field>({
        isLoading: false,
        content: "/img/screen_front_imei.png",
        isValid: false,
        placeholder: "",
        // Передача полей определяет отображение элементов
        // message: {
        //     type: "warn",
        //     text: "Чтобы уточнить грейд сделайте дополнительные фото",
        // },
        // comment: {
        //     description:
        //         "Возможный текстовый комментарий от оператора с пояснениями и причинами",
        //     list: ["Плохое освещение", "Точечные сколы"],
        // },
        wasUploaded: false,
        initialStatus: null,
        hideLoadButton: false,
    });

    /** Значения поля "Задняя сторона" */
    const back = reactive<I_EvaluationResult_Field>({
        isLoading: false,
        content: "/img/screen_front_imei.png",
        isValid: true, // ПОЛЮБОМУ true КОГДА hideLoadButton: true,
        // не очень удачно здесь для "evaluationResult" => "loadPanel"
        placeholder: "",
        wasUploaded: false,
        initialStatus: null,
        hideLoadButton: false,
        // comment: {
        //     description:
        //         "Возможный текстовый комментарий от оператора с пояснениями и причинами",
        //     list: ["Плохое освещение", "Точечные сколы"],
        // },
    });

    /** Значения поля "Левая боковая грань" */
    const leftSide = reactive<I_EvaluationResult_Field>({
        isLoading: false,
        content: "/img/screen_front_imei.png",
        isValid: false,
        placeholder: "",
        wasUploaded: false,
        initialStatus: null,
        hideLoadButton: false,
    });

    /** Значения поля "Правая боковая грань" */
    const rightSide = reactive<I_EvaluationResult_Field>({
        isLoading: false,
        content: "/img/screen_front_imei.png",
        isValid: false,
        placeholder: "",
        wasUploaded: false,
        initialStatus: null,
        hideLoadButton: false,
    });

    /** Значения поля "Верхняя грань" */
    const topSide = reactive<I_EvaluationResult_Field>({
        isLoading: false,
        content: "/img/screen_front_imei.png",
        isValid: false,
        placeholder: "",
        wasUploaded: false,
        initialStatus: null,
        hideLoadButton: false,
    });

    /** Значения поля "Нижняя грань" */
    const bottomSide = reactive<I_EvaluationResult_Field>({
        isLoading: false,
        content: "/img/screen_front_imei.png",
        isValid: false,
        placeholder: "",
        wasUploaded: false,
        initialStatus: null,
        hideLoadButton: false,
    });

    /** Получить наименования Полей для загрузки фото */
    const fieldsLineData = ref<I_EvaluationResult_FieldLineData>(null);

    /** Получить фотосет ID после загрузки фото в ИТ4 */
    const photoSetData = ref<I_EvaluationResult_photoSetData>(null);

    /** сгенерированная ссылка на QR-код */
    const qrPath = ref<null | string>(null);

    /** Состояние экрана */
    const stateOfEvaluation = ref<
        null | I_checkPhotoSetStatus_Return["data"]["state"]
    >(null);

    // |----------|
    // | COMPUTED |
    // |----------|

    /** Названия (name='...') типовых полей "Загрузить фото" */
    const fieldNamesData = computed<I_EvaluationResult_Field_Names[]>(() => {
        if (fieldsLineData.value === null) return [];

        return fieldsLineData.value.map(
            (lineData) => FIELD_NAMES_ADAPTER[lineData.side]
        );
    });

    /** Ко всем ли полям загружены фото? */
    const isAcceptedToNext = computed<boolean>(() => {
        const filedObject = {
            frontImei: frontImei,
            frontBattery: frontBattery,
            front: front,
            back: back,
            leftSide: leftSide,
            rightSide: rightSide,
            topSide: topSide,
            bottomSide: bottomSide,
        };

        return fieldNamesData.value.every((filedName) => {
            return filedObject[filedName].isValid;
        });
    });

    // |---------|
    // | METHODS |
    // |---------|

    /** Сбросить значения STORE на по-умолчанию */
    const restoreToDefault = () => {
        [
            frontImei,
            frontBattery,
            front,
            back,
            leftSide,
            rightSide,
            topSide,
            bottomSide,
        ].forEach((el) => {
            el.isLoading = false;
            el.content = "/img/screen_front_imei.png";
            el.isValid = false;
            el.wasUploaded = false;
            el.placeholder = "";
            el.initialStatus = null;
            el.hideLoadButton = false;
            delete el.comment;
            delete el.message;
        });

        photoSetData.value = null;
        fieldsLineData.value = null;
        qrPath.value = null;
        stateOfEvaluation.value = null;
    };

    /** Обнулить фото у поля */
    const removePhotoFromFiled = function (
        filedName: I_EvaluationResult_Field_Names
    ) {
        this[filedName].content = "/img/screen_front_imei.png";
        this[filedName].placeholder = "";
        this[filedName].isValid = false;
        this[filedName].wasUploaded = false;

        if (this[filedName].initialStatus) {
            if (
                this[filedName].initialStatus === "BAD_QUALITY" ||
                this[filedName].initialStatus === "IRRELEVANT"
            ) {
                this[filedName].isValid = false;
                this[filedName].message = {
                    type: "error",
                    text:
                        this[filedName].initialStatus === "BAD_QUALITY"
                            ? useTranslate(
                                  "tiw__loadPanel__downloadPhoto_badQuality"
                              )
                            : useTranslate(
                                  "tiw__loadPanel__downloadPhoto_irrelevant"
                              ),
                };
            }
            if (this[filedName].initialStatus === "AMBIGUOUS") {
                this[filedName].isValid = true;
                this[filedName].message = {
                    type: "warn",
                    text: useTranslate(
                        "tiw__loadPanel__downloadPhoto_ambiguous"
                    ),
                };
            }
        }
    };

    /** Проверяльщик были ли загрузки у полей по QR */
    const qrUploadChecker = (
        payload: I_checkPhotoSetStatus_Return__instructionField
    ) => {
        const fieldName = FIELD_NAMES_ADAPTER[payload.side];

        const filedObject = {
            frontImei: frontImei,
            frontBattery: frontBattery,
            front: front,
            back: back,
            leftSide: leftSide,
            rightSide: rightSide,
            topSide: topSide,
            bottomSide: bottomSide,
        };

        if (payload.wasUploadedAfterRemarks) {
            filedObject[fieldName].initialStatus = null;
            filedObject[fieldName].wasUploaded =
                payload.wasUploadedAfterRemarks;
            filedObject[
                fieldName
            ].content = `${I_AXIOS.IT4_HOST_PREVIEW}/attach/get2?ID=${payload.cid}`;
            filedObject[fieldName].isValid = true;
            filedObject[fieldName].hideLoadButton = true;
            filedObject[fieldName].placeholder = payload.cid;

            delete filedObject[fieldName].message;
        }
    };

    // |-------|
    // | WATCH |
    // |-------|

    watch(fieldsLineData, () => {
        console.log("watch");
        if (fieldsLineData.value === null) return;

        const filedObject = {
            frontImei: frontImei,
            frontBattery: frontBattery,
            front: front,
            back: back,
            leftSide: leftSide,
            rightSide: rightSide,
            topSide: topSide,
            bottomSide: bottomSide,
        };

        fieldNamesData.value.forEach((el, ind) => {
            filedObject[
                el
            ].content = `${I_AXIOS.IT4_HOST_PREVIEW}/attach/get2/${fieldsLineData.value[ind].cid}`;

            if (fieldsLineData.value[ind].comment) {
                filedObject[el].comment = {
                    list: fieldsLineData.value[ind].comment.split("\n"),
                };
            }

            if (fieldsLineData.value[ind].status === "AMBIGUOUS") {
                filedObject[el].initialStatus =
                    fieldsLineData.value[ind].status;

                if (fieldsLineData.value[ind].wasUploadedAfterRemarks) {
                    filedObject[el].wasUploaded =
                        fieldsLineData.value[ind].wasUploadedAfterRemarks;
                    filedObject[
                        el
                    ].content = `${I_AXIOS.IT4_HOST_PREVIEW}/attach/get2?ID=${fieldsLineData.value[ind].cid}`;
                    filedObject[el].isValid = true;
                    filedObject[el].hideLoadButton = true;
                    filedObject[el].placeholder = fieldsLineData.value[ind].cid;
                    delete filedObject[el].message;
                } else {
                    filedObject[el].isValid = true;
                    filedObject[el].message = {
                        type: "warn",
                        text: useTranslate(
                            "tiw__loadPanel__downloadPhoto_ambiguous"
                        ),
                    };
                }

                // Это значит что GRADED, но есть фото АМБИГУС которые мы пропустили
                // на прошлом этапе, например дозагрузили ОДНО фото из нескольких АМБИГУСОВ
                if (stateOfEvaluation.value === "GRADED") {
                    filedObject[el].isValid = true;
                    filedObject[el].hideLoadButton = true;
                    delete filedObject[el].message;

                    if (fieldsLineData.value[ind].questions) {
                        filedObject[el].comment = {
                            description:
                                fieldsLineData.value[ind].questions[0].question,
                            list: fieldsLineData.value[ind].questions[0]
                                .answers,
                        };
                    }
                }
            }

            if (
                fieldsLineData.value[ind].status === "BAD_QUALITY" ||
                fieldsLineData.value[ind].status === "IRRELEVANT"
            ) {
                filedObject[el].initialStatus =
                    fieldsLineData.value[ind].status;

                if (fieldsLineData.value[ind].wasUploadedAfterRemarks) {
                    filedObject[el].wasUploaded =
                        fieldsLineData.value[ind].wasUploadedAfterRemarks;
                    filedObject[
                        el
                    ].content = `${I_AXIOS.IT4_HOST_PREVIEW}/attach/get2?ID=${fieldsLineData.value[ind].cid}`;
                    filedObject[el].isValid = true;
                    filedObject[el].hideLoadButton = true;
                    filedObject[el].placeholder = fieldsLineData.value[ind].cid;
                } else {
                    filedObject[el].isValid = false;
                    filedObject[el].message = {
                        type: "error",
                        text:
                            fieldsLineData.value[ind].status === "BAD_QUALITY"
                                ? useTranslate(
                                      "tiw__loadPanel__downloadPhoto_badQuality"
                                  )
                                : useTranslate(
                                      "tiw__loadPanel__downloadPhoto_irrelevant"
                                  ),
                    };
                }
            }

            if (fieldsLineData.value[ind].status === "GRADED") {
                filedObject[el].isValid = true;
                filedObject[el].hideLoadButton = true;

                if (fieldsLineData.value[ind].questions) {
                    filedObject[el].comment = {
                        list: fieldsLineData.value[ind].questions
                            .map((el) => el.answers)
                            .flat(),
                    };
                }
            }

            if (fieldsLineData.value[ind].status === "ADDED") {
                if (fieldsLineData.value[ind].wasUploadedAfterRemarks) {
                    filedObject[el].wasUploaded =
                        fieldsLineData.value[ind].wasUploadedAfterRemarks;
                    filedObject[
                        el
                    ].content = `${I_AXIOS.IT4_HOST_PREVIEW}/attach/get2?ID=${fieldsLineData.value[ind].cid}`;
                    filedObject[el].isValid = true;
                    filedObject[el].hideLoadButton = true;
                    filedObject[el].placeholder = fieldsLineData.value[ind].cid;
                } else {
                    filedObject[el].isValid = true;

                    if (fieldsLineData.value[ind].questions) {
                        filedObject[el].comment = {
                            description:
                                fieldsLineData.value[ind].questions[0].question,
                            list: fieldsLineData.value[ind].questions[0]
                                .answers,
                        };
                    }
                }
            }
        });
    });

    return {
        // * 🆂🆃🅾🆁🅴
        frontImei,
        frontBattery,
        front,
        back,
        leftSide,
        rightSide,
        topSide,
        bottomSide,
        stateOfEvaluation,

        // * 🅳🅰🆃🅰
        fieldsLineData,
        photoSetData,
        qrPath,

        // * 🅲🅾🅼🅿🆄🆃🅴🅳
        fieldNamesData,
        isAcceptedToNext,

        // * 🅼🅴🆃🅷🅾🅳🆂
        restoreToDefault,
        removePhotoFromFiled,
        qrUploadChecker,
    };
});
