<template>
    <div :class="['tiw__field', { tiw__field_error: props.isError }]">
        <div v-if="$slots.title" class="tiw__field__title">
            <slot name="title"></slot>
        </div>
        <div
            v-if="$slots.hintTop"
            class="tiw__field__hint tiw__field__hint_top"
        >
            <slot name="hintTop"></slot>
        </div>
        <div class="tiw__field__body">
            <slot name="body"></slot>
        </div>
        <div
            v-if="$slots.hintBottom"
            class="tiw__field__hint tiw__field__hint_bottom"
        >
            <slot name="hintBottom"></slot>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    name: "FieldComponent",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [Filed]
 * Поле / обертка
 */

// Types
import type { I_Field } from "./field.types";

const props = defineProps<Partial<I_Field>>();
</script>

<style lang="scss" src="./field.scss"></style>
