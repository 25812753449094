<template>
    <!-- * Иконка 'Корзина' -->
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        :class="['tiw__icon']"
        v-bind="$attrs"
    >
        <path
            d="M2.5 5H4.16667H17.5"
            stroke="#EB001B"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M6.66797 5.0013V3.33464C6.66797 2.89261 6.84356 2.46868 7.15612 2.15612C7.46868 1.84356 7.89261 1.66797 8.33464 1.66797H11.668C12.11 1.66797 12.5339 1.84356 12.8465 2.15612C13.159 2.46868 13.3346 2.89261 13.3346 3.33464V5.0013M15.8346 5.0013V16.668C15.8346 17.11 15.659 17.5339 15.3465 17.8465C15.0339 18.159 14.61 18.3346 14.168 18.3346H5.83464C5.39261 18.3346 4.96868 18.159 4.65612 17.8465C4.34356 17.5339 4.16797 17.11 4.16797 16.668V5.0013H15.8346Z"
            stroke="#EB001B"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M8.33203 9.16797V14.168"
            stroke="#EB001B"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M11.668 9.16797V14.168"
            stroke="#EB001B"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script lang="ts">
export default {
    name: "TrashIcon",
};
</script>
