<template>
    <!-- * Иконка 'Стрелка в лево' -->
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        :class="['tiw__icon']"
        v-bind="$attrs"
    >
        <path
            d="M6.47656 3.33203L1.99851 7.9987L6.47656 12.6654"
            stroke="#808D9A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <line
            x1="0.75"
            y1="-0.75"
            x2="11.072"
            y2="-0.75"
            transform="matrix(1 0 0 -1 2.17969 7.35547)"
            stroke="#808D9A"
            stroke-width="1.5"
            stroke-linecap="round"
        />
    </svg>
</template>

<script lang="ts">
export default {
    name: "ArrowIcon",
};
</script>
