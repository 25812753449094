// Hooks
import it4CheckPhotoSetStatus from "@/hooks/requests/it4CheckPhotoSetStatus/it4CheckPhotoSetStatus";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";
import { useHowYouRateDeviceStore } from "@/stores/howYouRateDevice/howYouRateDevice";
import { useDownloadPhotosStore } from "@/stores/downloadPhotos/downloadPhotos";

// Types
import type { I_checkPhotoSetStatus_Return } from "@/hooks/requests/it4CheckPhotoSetStatus/it4CheckPhotoSetStatus.types";

interface usePhotoSetStatus__Payload {
    mock:
        | "GRADED"
        | "SKIPPED"
        // | "GRADING"
        | "OPPORTUNITY"
        | "UNKNOWN"
        | "TERMINATED";
    mockVariant?: "GRADED" | "ADDED";
}

/**
 * Проверить статус обработки фотосета оператором в ИТ4
 * (эта функция должна вызваться каждые ~30 секунд)
 * */
export default async function usePhotoSetStatus(
    payload?: usePhotoSetStatus__Payload
) {
    if (payload) {
        const photoSetStatus: I_checkPhotoSetStatus_Return["data"] = {
            // eslint-disable-next-line no-loss-of-precision
            photosetId: 240827070300643539,
            photosetNumber: "240800088",
            status: "ON_CORRECTION",
            deviceId: "240827070221652659",
            photosetImei: "123451234512239",
            deviceImei: "123451234512239",
            brandName: "APPLE",
            model: "iPhone 12 Pro Max",
            storage: "256 GB",
            color: "Gold",
            wic4key: "MGDE3                              ",
            wic: "2AMGDE3                            ",
            description: "iPhone 12 Pro Max 256GB Gold, Model A2411",
            grade: "B",
            gradeCode: "B",
            gradeName:
                'Грейд "B"                                                                                                                                                                                                                                                 ',
            gradeDescription: "Хорошее состояние",
            isTerminate: false,
            posGrade: "A+",
            price: 71000.0,
            posPrice: 106000.0,
            finalDiscount: 71000.0,
            isoCurrency: "KZT",
            progress: "PROCESSED",
            canCancel: false,
            canModify: false,
            canSendToCorrection: false,
            canSendGrade: false,
            canQuestionnaire: false,
            newPhotoExists: false,
            state: payload.mock,
            instructions: [
                {
                    side: "Back",
                    isIgnored: false,
                    isGraded: true,
                    status:
                        payload.mock === "UNKNOWN"
                            ? "BAD_QUALITY"
                            : "AMBIGUOUS",
                    sideDescription: "Задняя сторона",
                    cid: "20240827070300015825000000",
                    cidPreview: "20240404115352574435000000",
                    cidDevice: "20240827070300015825000000",
                    wasUploaded: true,
                    wasUploadedAfterRemarks: true,
                    lineId: "240827070224030368",
                    comment: "Размытое фото\nПлохое освещение",
                    text: "Чёткая фотография задней стороны",
                },
                {
                    side: "Left",
                    isIgnored: false,
                    isGraded: true,
                    status:
                        payload.mock === "UNKNOWN"
                            ? "BAD_QUALITY"
                            : "AMBIGUOUS",
                    sideDescription: "Левая боковая грань",
                    cid: "20240827070300119170000000",
                    cidPreview: "20240404115418257137000000",
                    cidDevice: "20240827070300119170000000",
                    wasUploaded: true,
                    wasUploadedAfterRemarks: true,
                    lineId: "240827070224030410",
                    comment: "Не тот ракурс",
                    text: "Чёткая фотография левой боковой грани",
                },
                {
                    side: "Right",
                    isIgnored: false,
                    isGraded: true,
                    status: "AMBIGUOUS",
                    sideDescription: "Правая боковая грань",
                    cid: "20240827070300231944000000",
                    cidPreview: "20240404115445160504000000",
                    cidDevice: "20240827070300231944000000",
                    wasUploaded: true,
                    wasUploadedAfterRemarks: false,
                    lineId: "240827070224030450",
                    text: "Чёткая фотография правой боковой грани",
                },
            ],
        };

        if (payload.mock === "OPPORTUNITY" && payload.mockVariant === "ADDED") {
            delete photoSetStatus.instructions;
            delete photoSetStatus.instructionsWithRemarks;

            photoSetStatus.instructions = [
                {
                    side: "Back",
                    isIgnored: false,
                    isGraded: true,
                    status: "ADDED",
                    sideDescription: "Задняя сторона",
                    cid: "20240827070300015825000000",
                    cidPreview: "20240404115352574435000000",
                    cidDevice: "20240827070300015825000000",
                    wasUploaded: true,
                    wasUploadedAfterRemarks: false,
                    lineId: "240827070224030368",
                    comment: "Размытое фото\nПлохое освещение",
                    text: "Чёткая фотография задней стороны",
                },
            ];
        }

        if (payload.mock === "GRADED") {
            // delete photoSetStatus.instructions;
            // delete photoSetStatus.instructionsWithRemarks;

            if (payload.mockVariant === "GRADED") {
                delete photoSetStatus.instructions;
                delete photoSetStatus.instructionsWithRemarks;
            }
            if (payload.mockVariant === "ADDED") {
                photoSetStatus.instructionsWithRemarks = [
                    {
                        side: "Front. Switch on",
                        isIgnored: false,
                        isGraded: true,
                        status: "GRADED",
                        sideDescription:
                            "Лицевая сторона с включенным экраном (IMEI, светлая тема)",
                        cid: "20240827070259612971000000",
                        cidPreview: "20240216160758486704000000",
                        cidDevice: "20240827070259612971000000",
                        questions: [
                            {
                                questionId: "231206192857958589",
                                question:
                                    "Есть ли дефекты изображения? Укажите все имеющиеся дефекты",
                                answers: [
                                    "Засвет и/или незначительное выгорание дисплея",
                                ],
                            },
                        ],
                        wasUploaded: true,
                        wasUploadedAfterRemarks: true,
                        lineId: "240827070224030232",
                        text: "Убедитесь, что на устройстве выбрана светлая тема и наберите *#06#",
                    },
                    {
                        side: "Front. Battery",
                        isIgnored: false,
                        isGraded: true,
                        status: "GRADED",
                        sideDescription:
                            "Экран с информацией о состоянии батареи",
                        cid: "20240827070259790836000000",
                        cidPreview: "20240404115536211293000000",
                        cidDevice: "20240827070259790836000000",
                        questions: [
                            {
                                questionId: "220710080134412723",
                                question: "Укажите состояние аккумулятора",
                                answers: ["86 - 89%", "81 - 85%"],
                            },
                        ],
                        wasUploaded: true,
                        wasUploadedAfterRemarks: true,
                        lineId: "240827070224030580",
                        text: "«Настройки» > «Аккумулятор» > «Состояние аккумулятора и зарядка»",
                    },
                ];
            }
        }

        return photoSetStatus;
    }

    const TIWStore = useTIWStore();
    const HowYouRateDeviceStore = useHowYouRateDeviceStore();
    const DownloadPhotosStore = useDownloadPhotosStore();

    const photoSetStatus = await it4CheckPhotoSetStatus({
        deviceId: HowYouRateDeviceStore.registeringDeviceData.deviceId,
        photosetId: DownloadPhotosStore.photoSetData.photosetId,
        token: TIWStore.WIDGET_DATA.token,
        lang: TIWStore.INJECTED_TIW.current_settings.locale,
    });

    return photoSetStatus;
}
