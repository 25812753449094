// Libs
import axios from "../configRequest";

// Data
import { I_AXIOS } from "../configRequest.model";

// Types
import type {
    I_setPhotoSetStatus,
    I_setPhotoSetStatus_Return,
} from "./it4SetPhotoSetStatus.types.ts";

/**
 * Установить статус обработки фотосета в ИТ4 на TO_GRADING
 * 15.6 документация ru 1.0.5 (d1b82f5)
 * */
const it4SetPhotoSetStatus: I_setPhotoSetStatus = async ({
    deviceId,
    photosetId,
    token,
    lang,
    status,
}) => {
    const setPhotoSetData = await axios.post<
        I_setPhotoSetStatus_Return["data"]
    >(
        `${I_AXIOS.STAGE_API_HOST}/api/tin/v1/site/quest/photoset/${deviceId}/${photosetId}`,
        {},
        {
            headers: {
                Accept: "application/json;charset=UTF-8",
                "Content-Type": "application/json",
            },
            params: {
                token,
                lang,
                status,
            },
        }
    );
    return setPhotoSetData.data;
};

export default it4SetPhotoSetStatus;
