import axios from "axios";

// Types / Data
import { I_AXIOS } from "./configRequest.model";

axios.defaults.headers.common["Authorization"] =
    "Bearer " + I_AXIOS.SPACE_TOKEN;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

export default axios;
