// Libs
import axios from "../configRequest";

// Data
import { I_AXIOS } from "../configRequest.model";

// Types
import type {
    I_it4CreateContract,
    I_it4CreateContract_Return,
} from "./it4CreateContract.types.js";

/**
 * Создать контракт в ИТ4
 * 16.2 документация ru 1.0.5 (d1b82f5)
 * */
export const it4CreateContract: I_it4CreateContract = async (payload) => {
    const contractData = await axios.post<I_it4CreateContract_Return["data"]>(
        `${I_AXIOS.STAGE_API_HOST}/api/tin/v1/site/quest/device/contract`,
        {},
        {
            headers: {
                Accept: "application/json;charset=UTF-8",
            },
            params: payload,
        }
    );
    // console.log(contractData.data);
    return contractData.data;
};

export default it4CreateContract;
