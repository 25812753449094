<template>
    <!-- * Иконка 'Шеврон / Галочка' -->
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        :class="['tiw__icon']"
        v-bind="$attrs"
    >
        <path
            d="M3.33203 6L7.97415 10.6421L12.6163 6"
            stroke="#808D9A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script lang="ts">
export default {
    name: "ChevronIcon",
};
</script>
