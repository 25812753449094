// Libs
import axios from "../configRequest";

// Data
import { I_AXIOS } from "../configRequest.model";

// Types
import type {
    I_apiGetProducts,
    I_apiGetProducts_Return,
} from "./apiGetProducts.types";

/** Получить Продукты */
const apiGetProducts: I_apiGetProducts = async (aboutProducts) => {
    const fetchData = await axios.post<I_apiGetProducts_Return>(
        I_AXIOS.SPACE_API_URL + "/calc/products",
        aboutProducts
    );
    // console.log(fetchData.data);
    return fetchData.data;
};

export default apiGetProducts;
