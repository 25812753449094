<template>
    <!-- * Иконка 'Чат' -->
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        :class="['tiw__icon']"
        v-bind="$attrs"
    >
        <path
            d="M21.82 12.0007C21.82 16.8203 17.4236 20.728 12.0018 20.728C10.3968 20.7321 8.81108 20.3784 7.35996 19.6927L2.18359 20.728L3.70541 16.6698C2.74214 15.3193 2.18359 13.7178 2.18359 12.0007C2.18359 7.18107 6.57996 3.27344 12.0018 3.27344C17.4236 3.27344 21.82 7.18107 21.82 12.0007Z"
            stroke="#808D9A"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z"
            fill="#6959CB"
        />
        <path
            d="M16.8016 13.2008C17.4643 13.2008 18.0016 12.6635 18.0016 12.0008C18.0016 11.338 17.4643 10.8008 16.8016 10.8008C16.1388 10.8008 15.6016 11.338 15.6016 12.0008C15.6016 12.6635 16.1388 13.2008 16.8016 13.2008Z"
            fill="#6959CB"
        />
        <path
            d="M7.2 13.2008C7.86274 13.2008 8.4 12.6635 8.4 12.0008C8.4 11.338 7.86274 10.8008 7.2 10.8008C6.53726 10.8008 6 11.338 6 12.0008C6 12.6635 6.53726 13.2008 7.2 13.2008Z"
            fill="#6959CB"
        />
    </svg>
</template>

<script lang="ts">
export default {
    name: "ChatIcon",
};
</script>
