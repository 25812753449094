export const suck =
    "background-color: cadetblue; padding: 4px 8px; border-radius: 2px; color: #fff; font-weight: 700;";

export const info =
    "background-color: dodgerblue; padding: 4px 8px; border-radius: 2px; color: #fff; font-weight: 700;";

export const warn =
    "background-color: darkorange; padding: 4px 8px; border-radius: 2px; color: #fff; font-weight: 700;";

export const dang =
    "background-color: red; padding: 4px 8px; border-radius: 2px; color: #fff; font-weight: 700;";
