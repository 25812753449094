// Libs
import axios from "../configRequest";

// Data
import { I_AXIOS } from "../configRequest.model";

// Types
import type {
    I_apiGetWidget,
    I_apiGetWidget_Return,
} from "./apiGetWidget.types";

/** Получить Данные об инициализируемом виджете (token, переводы и т.д.) */
const apiGetWidget: I_apiGetWidget = async (payload) => {
    const fetchData = await axios.post<I_apiGetWidget_Return>(
        I_AXIOS.SPACE_API_URL + "/widget/get",
        payload
    );
    // console.log(fetchData.data);
    return fetchData.data;
};

export default apiGetWidget;
