<template>
    <!-- * Иконка 'Карандаш / Редактировать' -->
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        :class="['tiw__icon']"
        v-bind="$attrs"
    >
        <path
            d="M2.44555 10.4784L11.7448 1.1791C11.7636 1.17332 11.7845 1.16721 11.8075 1.16099C11.9378 1.1257 12.1254 1.08903 12.3457 1.08599C12.7713 1.08012 13.3352 1.19662 13.8865 1.74798C14.4379 2.29934 14.5544 2.86321 14.5485 3.28883C14.5455 3.50911 14.5088 3.69676 14.4735 3.82702C14.4673 3.84998 14.4612 3.87091 14.4554 3.88967L5.15613 13.189L2.07593 13.5586L2.44555 10.4784ZM14.417 3.9991L14.4178 3.99723C14.4171 3.9988 14.4168 3.99954 14.4168 3.99942L14.417 3.9991Z"
            stroke="#808D9A"
            stroke-width="1.5"
            stroke-linecap="round"
        />
        <path
            d="M9.51172 2.35547L13.1034 5.94712"
            stroke="#808D9A"
            stroke-width="1.5"
        />
    </svg>
</template>

<script lang="ts">
export default {
    name: "EditIcon",
};
</script>
