<template>
    <!-- * Иконка 'Восклицательный знак в треугольнике' -->
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        :class="['tiw__icon']"
        v-bind="$attrs"
    >
        <path
            d="M8.57465 3.21635L1.51632 14.9997C1.37079 15.2517 1.29379 15.5374 1.29298 15.8284C1.29216 16.1195 1.36756 16.4056 1.51167 16.6585C1.65579 16.9113 1.86359 17.122 2.11441 17.2696C2.36523 17.4171 2.65032 17.4965 2.94132 17.4997H17.058C17.349 17.4965 17.6341 17.4171 17.8849 17.2696C18.1357 17.122 18.3435 16.9113 18.4876 16.6585C18.6317 16.4056 18.7071 16.1195 18.7063 15.8284C18.7055 15.5374 18.6285 15.2517 18.483 14.9997L11.4247 3.21635C11.2761 2.97144 11.0669 2.76895 10.8173 2.62842C10.5677 2.48789 10.2861 2.41406 9.99965 2.41406C9.71321 2.41406 9.43159 2.48789 9.18199 2.62842C8.93238 2.76895 8.72321 2.97144 8.57465 3.21635V3.21635Z"
            stroke="#F79009"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M10 7.5V10.8333"
            stroke="#F79009"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M10 14.168H10.0083"
            stroke="#F79009"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script lang="ts">
export default {
    name: "AlertIcon",
};
</script>
