<template>
    <Modal class="tiw__step__operatorExecuting">
        <template #header>
            <h2 class="tiw__step__operatorExecuting__header">
                {{ OperatorExecutingStore.currentStatusMessages.header }}
            </h2>
        </template>

        <template v-if="isSorry">
            <div class="tiw__step__operatorExecuting__sorry">
                <HandingIcon />
                <p>
                    {{ useTranslate("tiw__operatorExecuting__sorryText") }}
                </p>
            </div>
        </template>

        <div v-show="!isSorry">
            <Timer
                ref="refToTimer"
                :minutes="5"
                :emit-per-seconds="30"
                :is-pause="isPause"
                @emit-interval="IntervalHandler"
                @emit-second="everySecondHandler"
            />
        </div>
        <Stepper
            v-show="!isSorry"
            :status="OperatorExecutingStore.status"
            :message="OperatorExecutingStore.currentStatusMessages.message"
        />
    </Modal>
</template>

<script lang="ts">
export default {
    name: "OperatorExecuting",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [OperatorExecuting]
 * Модальное окно / экран "Статусы оператора"
 */

// Components
import Modal from "@/components/modal/modal.vue";
import Stepper from "@/components/stepper/stepper.vue";
import Timer from "@/components/timer/timer.vue";

// Icons
import HandingIcon from "@/ui/icons/handing.vue";

// Data
import { GRADES_DATA } from "@/stores/howYouRateDevice/howYouRateDevice.model";

// Hooks
import { ref } from "vue";
import usePhotoSetStatus from "@/hooks/usePhotoSetStatus/usePhotoSetStatus";
import useTradeInCalculation from "@/hooks/useTradeInCalculation/useTradeInCalculation";
import useTranslate from "@/hooks/useTranslate/useTranslate";
import useQRPath from "@/hooks/useQRPath/useQRPath";

// Types
import type { I_TimerData } from "~/tiw/components/timer/timer.types";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";
import { useHowYouRateDeviceStore } from "@/stores/howYouRateDevice/howYouRateDevice";
import { useOperatorExecutingStore } from "@/stores/operatorExecuting/operatorExecuting";
import { useEvaluationResultStore } from "@/stores/evaluationResult/evaluationResult";

const TIWStore = useTIWStore();
const HowYouRateDeviceStore = useHowYouRateDeviceStore();
const OperatorExecutingStore = useOperatorExecutingStore();
const EvaluationResultStore = useEvaluationResultStore();

const isSorry = ref<boolean>(false);
const isPause = ref<boolean>(false);

const refToTimer = ref(null);

// Каждые [30] секунд опрос сервера для статуса обработки фото
const IntervalHandler = async (t_data: I_TimerData) => {
    if (t_data.minutes === "00" && t_data.seconds === "00") {
        isSorry.value = true;
    }

    const photoSetStatus = await usePhotoSetStatus();

    const prevProgress = OperatorExecutingStore.status;

    // Переключение экранов "Идёт изучение фотографий"
    OperatorExecutingStore.status = photoSetStatus.progress;

    // Если статус обновился, а время истекло
    // рестарт таймера и ждём дальше 5 минут
    if (prevProgress !== photoSetStatus.progress && isSorry.value) {
        refToTimer.value.restart();
        isSorry.value = false;
    }

    if (photoSetStatus.state === "GRADING") {
        return;
    }

    EvaluationResultStore.restoreToDefault();
    EvaluationResultStore.stateOfEvaluation = photoSetStatus.state;

    if (photoSetStatus.grade) {
        // Устанавливаем значение грейда из ответа ответа на вопрос
        HowYouRateDeviceStore.grade = photoSetStatus.grade;
        // Устанавливаем описание значения грейда "Как новый", "С дефектами"
        HowYouRateDeviceStore.rate = useTranslate(
            `tiw__grades__${
                GRADES_DATA[photoSetStatus.grade].gradeCode
            }_stateInfo`
        );

        HowYouRateDeviceStore.deviceGrade = {
            title: useTranslate(
                `tiw__grades__${
                    GRADES_DATA[photoSetStatus.grade].gradeCode
                }_description`
            ),
        };
    }

    // Переключение на "Все фото обработаны"
    if (photoSetStatus.progress === "PROCESSED") {
        if (photoSetStatus.state === "SKIPPED") {
            isPause.value = true;

            console.log(
                "Получена оценка Грейда",
                "грейд пропущен",
                "считать по прошлым результатам 'successfulEvaluation'"
            );

            await new Promise((res) =>
                setTimeout(() => {
                    res(1);
                    TIWStore.switchStep("successfulEvaluation");
                }, 3000)
            );
            return;
        }

        if (photoSetStatus.state === "TERMINATED") {
            isPause.value = true;

            console.log(
                "Получена оценка Грейда",
                "устройство не подлежит выкупу",
                "завершение оценки"
            );
            HowYouRateDeviceStore.deviceGrade = null;
            await new Promise((res) =>
                setTimeout(() => {
                    res(1);
                    TIWStore.switchStep("evaluationResult");
                }, 3000)
            );
            return;
        }

        if (
            photoSetStatus.state === "OPPORTUNITY" ||
            photoSetStatus.state === "UNKNOWN"
        ) {
            console.log(
                "Получена оценка Грейда",
                "Грейд не определён, нужны доп. фото",
                "пересчет по результатам ответа"
            );

            EvaluationResultStore.fieldsLineData = photoSetStatus.instructions;

            useQRPath();

            if (photoSetStatus.state === "OPPORTUNITY") {
                useTradeInCalculation({
                    tradeInPrice: photoSetStatus.finalDiscount.toString(),
                });
                HowYouRateDeviceStore.deviceGrade.info = useTranslate(
                    "tiw__handedDevice__opportunity"
                );
            } else {
                HowYouRateDeviceStore.deviceGrade = null;
            }

            isPause.value = true;
            await new Promise((res) =>
                setTimeout(() => {
                    res(1);
                    TIWStore.switchStep("evaluationResult");
                }, 3000)
            );
            return;
        }

        if (photoSetStatus.state === "GRADED") {
            if (
                photoSetStatus.instructionsWithRemarks &&
                photoSetStatus.instructionsWithRemarks.length
            ) {
                EvaluationResultStore.fieldsLineData =
                    photoSetStatus.instructionsWithRemarks;
            }

            useTradeInCalculation({
                tradeInPrice: photoSetStatus.finalDiscount.toString(),
            });
            isPause.value = true;

            await new Promise((res) =>
                setTimeout(() => {
                    res(1);
                    TIWStore.switchStep("evaluationResult");
                }, 3000)
            );

            return;
        }

        console.log("[UNCORRECTED_FLOW]");
        EvaluationResultStore.stateOfEvaluation = "TERMINATED";
        TIWStore.switchStep("evaluationResult");
    }
};

// Он в необходим для изменения ползунка свернутого статуса модалки
const everySecondHandler = (data: number) => {
    OperatorExecutingStore.percent = data;
};
</script>

<style lang="scss" src="./operatorExecuting.scss"></style>
