// Libs
import axios from "../configRequest";

// Data
import { I_AXIOS } from "../configRequest.model";

// Types
import type {
    I_it4PhotoDownloader,
    I_it4PhotoDownloader_Return,
} from "./it4PhotoDownloader.types";

/**
 * Загрузить фото в ИТ4
 * 15.2 документация ru 1.0.5 (d1b82f5)
 * */
export const it4PhotoDownloader: I_it4PhotoDownloader = async (
    deviceId,
    payload
) => {
    const loadPhotoFieldsData = await axios.post<
        I_it4PhotoDownloader_Return["data"]
    >(
        `${I_AXIOS.STAGE_API_HOST}/api/tin/v1/site/quest/photos/${deviceId}`,
        payload,
        {
            headers: {
                Accept: "application/json;charset=UTF-8",
            },
        }
    );
    // console.log(loadPhotoFieldsData.data);
    return loadPhotoFieldsData.data;
};

export default it4PhotoDownloader;
