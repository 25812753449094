<template>
    <div
        :class="{
            tiw__handedDevice: true,
            tiw__handedDevice_noGrade: props.deviceTerminated,
        }"
    >
        <div class="tiw__handedDevice__container">
            <Button
                class="tiw__handedDevice__edit"
                type="button"
                variant="unset"
                @click="onEditHandler"
            >
                <EditIcon />
            </Button>
            <figure class="tiw__handedDevice__figure">
                <img
                    class="tiw__handedDevice__image"
                    :src="props.devicePreview"
                    alt="Handed device"
                />
            </figure>

            <div class="tiw__handedDevice__body">
                <h4 class="tiw__handedDevice__title">
                    {{ useTranslate("tiw__handedDevice__title") }}
                </h4>

                <ul
                    v-if="props.deviceChain"
                    class="tiw__handedDevice__device-chain"
                >
                    <li
                        v-for="(chainElement, ind) in props.deviceChain"
                        :key="'chain_' + ind"
                    >
                        <span>{{ chainElement }}</span>
                    </li>

                    <li v-if="props.deviceState">
                        {{ props.deviceState }}
                    </li>
                </ul>
            </div>
        </div>
        <div v-if="props.deviceGrade" class="tiw__handedDevice__current-grade">
            <h5 class="tiw__title">
                {{ props.deviceGrade.title }}
            </h5>
            <p v-if="props.deviceGrade.info" class="tiw__text">
                {{ props.deviceGrade.info }}
            </p>
        </div>
        <!-- Ошибка что Гдейд девайса не определен -->
        <div v-if="props.deviceNoGrade" class="tiw__handedDevice__no-grade">
            <span>
                {{ useTranslate("tiw__handedDevice__unknownGrade") }}
            </span>
        </div>
        <!-- Ошибка что девайс к выкупу не подлежит  -->
        <div v-if="props.deviceTerminated" class="tiw__handedDevice__no-grade">
            <span>
                {{ useTranslate("tiw__handedDevice__deviceTerminated") }}
            </span>
        </div>
    </div>
</template>

<script lang="ts">
export default {
    name: "HandedDevice",
};
</script>

<script setup lang="ts">
/**
 * * 🅲🅾🅼🅿🅾🅽🅴🅽🆃 [HandledDevice]
 * Плитка сдаваемого устройства
 */

// Types
import type { I_HandedDevice } from "./handedDevice.types.ts";

// Components
import Button from "@/ui/button/button.vue";

// Icons
import EditIcon from "@/ui/icons/edit.vue";

// Hooks
import useTranslate from "@/hooks/useTranslate/useTranslate";

const props = defineProps<I_HandedDevice>();

const onEditHandler = () => {
    if (props.onEdit) {
        props.onEdit();
        return;
    }
};
</script>

<style lang="scss" src="./handedDevice.scss"></style>
