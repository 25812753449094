<template>
    <!-- * Иконка 'Часовые стрелки внутри круговой стрелки' -->
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        :class="['tiw__icon']"
        v-bind="$attrs"
    >
        <g clip-path="url(#clip0_26875_38015)">
            <path
                d="M24 12C24 18.633 18.6321 24 12 24C5.367 24 0 18.6321 0 12C0 5.367 5.36792 0 12 0C14.4994 0 16.882 0.758972 18.8849 2.16797V1.04865C18.8849 0.530823 19.3046 0.111145 19.8224 0.111145C20.3401 0.111145 20.7599 0.530823 20.7599 1.04865V4.17755C20.7599 4.64062 20.4201 5.03925 19.9537 5.1048C19.8354 5.12146 20.1021 5.1134 16.6934 5.11505C16.1755 5.11505 15.7559 4.69537 15.7559 4.17755C15.7559 3.65973 16.1755 3.24005 16.6934 3.24005H17.0845C10.4471 -0.627136 1.875 4.11127 1.875 12C1.875 17.5966 6.40411 22.125 12 22.125C17.5966 22.125 22.125 17.5959 22.125 12C22.125 11.4822 22.5447 11.0625 23.0625 11.0625C23.5803 11.0625 24 11.4822 24 12ZM12.0355 5.53125C11.5177 5.53125 11.098 5.95093 11.098 6.46875V11.9465C11.098 12.195 11.1967 12.4336 11.3725 12.6094L15.1135 16.3504C15.4797 16.7164 16.0734 16.7166 16.4394 16.3504C16.8056 15.9842 16.8056 15.3907 16.4394 15.0245L12.973 11.5582V6.46875C12.973 5.95093 12.5533 5.53125 12.0355 5.53125Z"
                fill="white"
            />
        </g>
        <defs>
            <clipPath id="clip0_26875_38015">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script lang="ts">
export default {
    name: "TimeIcon",
};
</script>
