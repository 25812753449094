<template>
    <Container>
        <section :class="['tiw__modal', 'tiw__step']">
            <!-- <BuyDevice v-if="TIWStore.CURRENT_STEP === 'buyDevice'" /> -->
            <ChooseDevice v-if="TIWStore.CURRENT_STEP === 'chooseDevice'" />
            <HowYouRateDevice
                v-if="TIWStore.CURRENT_STEP === 'howYouRateDevice'"
            />
            <EnterImei v-if="TIWStore.CURRENT_STEP === 'enterImei'" />
            <DownloadPhotos
                v-show="TIWStore.CURRENT_STEP === 'downloadPhotos'"
            />
            <OperatorExecuting
                v-if="TIWStore.CURRENT_STEP === 'operatorExecuting'"
            />
            <SuccessfulEvaluation
                v-if="TIWStore.CURRENT_STEP === 'successfulEvaluation'"
            />
            <EvaluationResult
                v-if="TIWStore.CURRENT_STEP === 'evaluationResult'"
            />
            <!-- <SendSmsContract
                v-if="TIWStore.CURRENT_STEP === 'sendSmsContract'"
            /> -->
            <AddToCart v-if="TIWStore.CURRENT_STEP === 'addToCart'" />
            <!-- Решили пока что убрать -->
            <!-- <ThanksForOrder v-if="TIWStore.CURRENT_STEP === 'thanksForOrder'" /> -->
        </section>
    </Container>
</template>

<script lang="ts">
export default {
    name: "TradeInWidget",
    inject: ["appInstance", "widget"],
};
</script>

<script setup lang="ts">
// Global styles
import "@/scss/global.scss";

// Components
import Container from "@/components/container/container.vue";

// Modal Steps
// import BuyDevice from "@/components/modalSteps/buyDevice/buyDevice.vue";  // Решили временно убрать
import ChooseDevice from "@/components/modalSteps/chooseDevice/chooseDevice.vue";
import HowYouRateDevice from "@/components/modalSteps/howYouRateDevice/howYouRateDevice.vue";
import EnterImei from "@/components/modalSteps/enterImei/enterImei.vue";
import DownloadPhotos from "@/components/modalSteps/downloadPhotos/downloadPhotos.vue"; // [V-SHOW] что бы не сбрасывались данные нативного input.file при размонтировании компонента из DOM
import OperatorExecuting from "@/components/modalSteps/operatorExecuting/operatorExecuting.vue";
import SuccessfulEvaluation from "@/components/modalSteps/successfulEvaluation/successfulEvaluation.vue";
import EvaluationResult from "@/components/modalSteps/evaluationResult/evaluationResult.vue";
// import SendSmsContract from "@/components/modalSteps/sendSmsContract/sendSmsContract.vue"; // Решили временно убрать
import AddToCart from "@/components/modalSteps/addToCart/addToCart.vue";
// import ThanksForOrder from "@/components/modalSteps/thanksForOrder/thanksForOrder.vue"; // Решили временно убрать

// Hooks
import { onMounted, onBeforeMount } from "vue";
import { suck, warn } from "@/helpers/consoleColors";
import apiGetWidget from "@/hooks/requests/apiGetWidget/apiGetWidget";
import useBrands from "@/hooks/useBrands/useBrands";

// Store
import { useTIWStore } from "@/stores/tiw/tiw";

// Data
import TRANSLATIONS from "@/helpers/translations";

const TIWStore = useTIWStore();

onBeforeMount(async () => {
    const widgetData = await apiGetWidget({
        domain: TIWStore.INJECTED_TIW.current_settings.domain,
    });

    widgetData.data.translations = TRANSLATIONS;

    TIWStore.WIDGET_DATA = widgetData.data;

    await useBrands();
});

onMounted(() => {
    console.log("%c[TIWidget] -> APP is Mounted", suck);
    console.log(
        "%c[TIWidget] -> press to any [Trade-In with Good] button",
        warn
    );
    TIWStore.INJECTED_TIW.current_api.onLoad();
});
</script>
