// Hooks
import useTranslate from "@/hooks/useTranslate/useTranslate";

// Types
import type { I_UseValidate_Return } from "../index";

/** Функция-валидатор для поля "Телефон" */
export default function phoneValidator(value: string): I_UseValidate_Return {
    if (!value) {
        return {
            status: false,
            message: useTranslate("tiw__validation__phone_required"),
        };
    }

    // Если длина от 14 до 18 - true
    if (!/^.{14,18}$/.test(value)) {
        return {
            status: false,
            message: useTranslate("tiw__validation__phone_range"),
        };
    }

    return {
        status: true,
        message: useTranslate("tiw__validation__success"),
    };
}
