// Libs
import axios from "../configRequest";

// Data
import { I_AXIOS } from "../configRequest.model";

// Types
import type {
    I_apiGetModels,
    I_apiGetModels_Return,
} from "./apiGetModels.types.";

/** Получить Модели */
const apiGetModels: I_apiGetModels = async (aboutModels) => {
    const fetchData = await axios.post<I_apiGetModels_Return>(
        I_AXIOS.SPACE_API_URL + "/calc/models",
        aboutModels
    );
    // console.log(fetchData.data);
    return fetchData.data;
};

export default apiGetModels;
