// Hooks
import useTranslate from "@/hooks/useTranslate/useTranslate";

// Components
import imeiValidator from "./validators/imei";
import fileValidator from "./validators/file";
import fioValidator from "./validators/fio";
import innValidator from "./validators/inn";
import phoneValidator from "./validators/phone";
import emailValidator from "./validators/email";

type I_UseValidate_Params = {
    target: "imei" | "fio" | "inn" | "email" | "phone" | "file";
    value: string | HTMLInputElement;
};

export type I_UseValidate_Return = {
    status: boolean;
    message: string;
};

/** Верхнеуровневая функция-валидатор */
export default function useValidate({
    target,
    value,
}: I_UseValidate_Params): I_UseValidate_Return {
    switch (target) {
        case "imei": {
            return imeiValidator(value as string);
        }
        case "file": {
            return fileValidator(value as HTMLInputElement);
        }
        case "fio": {
            return fioValidator(value as string);
        }
        case "inn": {
            return innValidator(value as string);
        }
        case "email": {
            return emailValidator(value as string);
        }
        case "phone": {
            return phoneValidator(value as string);
        }
        default:
            return {
                status: false,
                message: useTranslate("tiw__validation__undefined"),
            };
    }
}
